<template>
  <div class="wrap">
    <TablePage ref="tablePage" v-model="options" @handleEvent="handleEvent" />
    <Dialog :options.sync="dialogOptions" @handleEvent="handleEvent" />
  </div>
</template>
<script>
import TablePage from '@/components/tablePage'
import Dialog from '@/components/Dialog'
import { getManList, remove } from '@/api/shop/mobileQueryApp/index.js'

export default {
  name: 'QueryManSet',
  components: { TablePage, Dialog },
  data () {
    return {
      dialogOptions: {
        title: '',
        width: 620,
        show: false,
        type: 'QueryManSet',
        formData: null
      },
      options: {
        mutiSelect: true, // 多选
        loading: true,
        pagination: {
          total: 0,
          page: 1,
          size: 15
        },
        getListApi: getManList,
        body: {},
        listNo: true, // 序号
        title: '营业通账号管理',
        check: [], // 选中数据
        rowKey: 'appManId',
        search: [
          {
            type: 'filters',
            tip: '员工编号/姓名/手机号',
            model: '',
            filters: [
              { filter: 'query', label: '全部' },
              { filter: 'appManNo', label: '员工编号' },
              { filter: 'appManName', label: '姓名' },
              { filter: 'appManTel', label: '手机号' }
            ]
          },
          { type: 'button', tip: '查询', btnType: 'primary', click: 'search' }
        ],
        buttons: [
          {
            click: 'add',
            label: '新增',
            icon: 'el-icon-plus',
            type: 'primary'
          },
          {
            click: 'del',
            label: '删除',
            type: 'danger',
            icon: 'el-icon-delete',
            disabled: () => !this.options?.check?.length
          },
          {
            click: 'refresh',
            label: '刷新',
            icon: 'el-icon-refresh',
            right: true,
            type: ''
          }
        ],
        columns: [
          {
            prop: 'appManNo',
            label: '员工编号',
            type: 'link',
            click: 'update',
            minWidth: 110
          },
          {
            prop: 'appManName',
            label: '员工姓名',
            minWidth: 110
          },
          {
            prop: 'telephone',
            label: '手机号',
            minWidth: 100
          },
          {
            prop: 'shopIds',
            label: '所属门店',
            type: 'tag',
            tagDefault: '全部门店',
            tagKey: 'shopInfos',
            tagLabel: 'shopName',
            minWidth: 250
          },
          {
            prop: 'remark',
            label: '备注',
            minWidth: 100
          },
          {
            prop: 'deleteFlag',
            label: '删除状态',
            minWidth: 80
          },
          {
            prop: 'createBy',
            label: '创建人',
            minWidth: 80
          },
          {
            prop: 'createTime',
            label: '创建时间',
            minWidth: 150
          },
          {
            prop: 'updateBy',
            label: '修改人',
            minWidth: 80
          },
          {
            prop: 'updateTime',
            label: '修改时间',
            minWidth: 150
          }
        ],
        list: []
      }
    }
  },
  methods: {
    async handleEvent (type, row) {
      switch (type) {
        case 'dialogChange':
          this.$refs.tablePage.getList()
          break
        case 'update':
          {
            const options = {
              show: true,
              title: '编辑营业通员工',
              click: type,
              formData: row
            }
            this.dialogOptions = { ...this.dialogOptions, ...options }
          }
          break
        case 'add':
          {
            const options = {
              show: true,
              title: '新增营业通员工',
              click: type,
              formData: {}
            }
            this.dialogOptions = { ...this.dialogOptions, ...options }
          }
          break
        case 'del': // 选中数据 Array
          try {
            await remove(this.options.check.map(x => x.appManId))
            this.$message.success('删除成功！')
            this.$refs.tablePage.getList()
          } catch (error) {}
          break
        default:
          break
      }
    }
  }
}
</script>
<style lang="scss" scoped>
.wrap {
  .dialog-footer {
    height: 80px;
  }
}
</style>
