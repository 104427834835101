<template>
  <div class="wrapIndex">
    <cardTitleCom cardTitle="开通营业通">
      <template slot="cardContent">
        <div class="topImg" :style="`background-image:url(${require('@/assets/images/goBuyActivate-topBack.png')});`">
          <div class="topText">
            <div class="title22">老板的烦恼</div>
            <div>
              1.老板经常出差在外，迫切希望能掌控店里的销售汇总情况，利润跟踪，需要不停打电话询问，麻烦。
            </div>
            <div>2.打开笔记本、台式电脑查看各大报表汇总？不方便。</div>
            <div>3.老板要亲自去操做ERP软件才能看到报表，哪有这个时间。</div>
            <div>
              4.老板要等着员工发来短信、邮件报告才知道销售状况，信息传递太滞后。
            </div>
          </div>
        </div>
        <div class="topContent">
          <div class="text">
            <div class="text-content">
              <el-image :src="require('@/assets/images/goBuyActivate-icon1.png')" class="icon" />
              <div class="left-text">
                <el-image :src="require('@/assets/images/goBuyActivate-text1.png')" class="icon" />
                <div>
                  营业通可以随时查看门店的销售、库存、客流等数据，及时掌握门店的运营状况，做出有效的决策。
                </div>
              </div>
            </div>
            <div class="text-content">
              <el-image :src="require('@/assets/images/goBuyActivate-icon2.png')" class="icon" />
              <div class="left-text">
                <el-image :src="require('@/assets/images/goBuyActivate-text2.png')" class="icon" />
                <div>
                  营业通可以自定义报表的内容、格式、时间范围等，根据需要展示不同的数据维度，满足个性化需求。
                </div>
              </div>
            </div>
            <div class="text-content">
              <el-image :src="require('@/assets/images/goBuyActivate-icon3.png')" class="icon" />
              <div class="left-text">
                <el-image :src="require('@/assets/images/goBuyActivate-text3.png')" class="icon" />
                <div>
                  营业通可以将数据以图表等形式呈现，一目了然，轻松分析数据的趋势、比较、分布等，提高数据的直观性和可用性。
                </div>
              </div>
            </div>
            <div class="text-content">
              <el-image :src="require('@/assets/images/goBuyActivate-icon4.png')" class="icon" />
              <div class="left-text">
                <el-image :src="require('@/assets/images/goBuyActivate-text4.png')" class="icon" />
                <div>
                  营业通可以在任何地点、任何时间，通过手机访问门店的数据，无需依赖电脑，提高工作效率和灵活性。
                </div>
              </div>
            </div>
            <div class="text-content">
              <el-image :src="require('@/assets/images/goBuyActivate-icon5.png')" class="icon" />
              <div class="left-text">
                <el-image :src="require('@/assets/images/goBuyActivate-text5.png')" class="icon" />
                <div>
                  营业通可以通过简单的操作，快速生成和查看报表，无需复杂的设置和操作，节省时间和精力。
                </div>
              </div>
            </div>
          </div>
          <el-image :src="require('@/assets/images/goBuyActivate-phone.png')" class="img" />
        </div>
        <div class="buttons">
          <div class="button" @click="goBuy">
            {{ price }} {{ isBuy == 'buy' ? '立即开通' : '立即续费' }}
          </div>
          <el-link type="primary" class="text-button" @click="goList">查询订单列表></el-link>
        </div>
      </template>
    </cardTitleCom>
  </div>
</template>
<script>
import { getMinPay } from '@/utils'
import cardTitleCom from '@/views/components/cardTitleCom' //卡片标题
import {
  getOrderDetail,
  getTradeProductToRenew
} from '@/api/shop/mobileQueryApp/index.js'
export default {
  name: 'goBuyIndex',
  components: { cardTitleCom },
  data() {
    return {
      renewObj: {},
      isBuy: 'buy',
      price: '---'
    }
  },
  async mounted() {
    // 获取价格
    this.getOrder()
  },
  methods: {
    async getOrder() {
      try {
        const { data } = await this.getDicts('product_years_package_mode')
        const yearsData = data || []
        //判断是新增还是续费
        const res1 = await getTradeProductToRenew()
        if (res1.type == 'renew') {
          this.renewObj = res1.data
        }
        this.isBuy = res1.type
        const res = await getOrderDetail()
        console.log('res', res)
        const res2 = getMinPay(res.data, res1.type, yearsData)
        console.log('res2', res2)
        this.price = `￥${res2.pay}/${res2.text
          } 起`
      } catch (error) {
        console.log('priceArr error ', error)
      }
    },
    goBuy() {
      if (this.isBuy == 'buy') {
        this.$emit('update:active', 2)
        this.$emit('resume', {})
      } else {
        let option = {
          productId: this.renewObj.productId,
          productBookDetailIds: this.renewObj.productBookDetailId ? [this.renewObj.productBookDetailId] : [],
          tenantId: this.$store.state.user.userinfo.tenantId
        }
        this.$emit('activeInfo', option)
        this.$emit('resume', {})
      }
    },
    goList() {
      this.$emit('update:active', 3)
    },
  }
}
</script>
<style lang="scss" scoped>
.wrapIndex {
  height: 100%;
  padding: 6px 10px;
  ::v-deep .cardContent {
    padding: 10px;
  }

  .topImg {
    position: relative;
    height: 200px;
    background-repeat: no-repeat;
    background-size: 100%;

    .topText {
      position: absolute;
      left: 12%;
      top: 9%;
      color: #ffffff;
      font-size: 14px;

      .title22 {
        font-size: 36px;
        font-family: CKTKingKong;
        font-weight: bold;
        color: #ffffff;
        text-shadow: 0px 1px 2px rgba(177, 217, 241, 0.66);
        background: linear-gradient(0deg, #a1daff 0%, #ffffff 100%);
        -webkit-background-clip: text;
        -webkit-text-fill-color: transparent;
        margin-bottom: 10px;
      }
    }
  }

  .topContent {
    display: flex;
    padding: 30px;

    .text {
      flex: 1;
      padding-left: 20px;
      margin-right: 10px;
      display: flex;
      flex-direction: column;
      justify-content: space-evenly;

      .text-content {
        display: flex;

        ::v-deep .el-image__inner {
          width: 49px !important;
          height: 54px !important;
        }

        .left-text {
          padding-left: 20px;
          display: flex;
          flex-direction: column;
          justify-content: space-evenly;
          font-size: 14px;

          ::v-deep .el-image__inner {
            width: 58px !important;
            height: 18px !important;
          }
        }
      }
    }
  }

  .buttons {
    margin: 0 20px;
    display: flex;
    align-items: flex-end;

    .button {
      width: 220px;
      height: 48px;
      line-height: 48px;
      text-align: center;
      background: #f2f2f2;
      border-radius: 24px;
      font-size: 14px;
      color: #ffffff;
      background-color: #ff9e40;
      overflow: hidden;
      cursor: pointer;
    }

    .text-button {
      margin-left: 20px;
      font-size: 14px;
      height: 18px;
      margin-bottom: 10px;
    }
  }
}
</style>
