var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", [
    _c("div", { ref: "qrCodeDiv", attrs: { id: "verificationCode" } }),
    _c(
      "div",
      {
        directives: [
          {
            name: "show",
            rawName: "v-show",
            value: false,
            expression: "false",
          },
        ],
        attrs: { id: "barcodeBox" },
      },
      [
        _c("span", [_vm._v("核销码: ")]),
        _c("img", {
          ref: "barcode",
          staticStyle: { width: "100px", height: "100px" },
          attrs: { id: "barcode" },
        }),
      ]
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }