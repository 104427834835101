<template>
  <div class="wrap" id="wrap">
    <div class="save">
      <el-button
        class="marR10 marB5"
        type="primary"
        size="mini"
        @click="saveAllData"
        >保存</el-button
      >
    </div>
    <cardTitleCom cardTitle="订单管理设置" id="search-card">
      <template #leftCardTitle>
        <el-radio-group
          v-model="queryParams.manageType"
          @change="handleShop"
          size="mini"
        >
          <el-radio-button :label="2">门店订单管理</el-radio-button>
          <el-radio-button :label="1">总部订单管理</el-radio-button>
        </el-radio-group>
      </template>
      <template #rightCardTitle>
        <selectAllFilter v-model="queryParams" :filters="filters" />
        <el-button
          class="marL10 marR10"
          type="primary"
          size="mini"
          @click="getList"
          >查询</el-button
        >
      </template>
      <template slot="cardContent">
        <div class="tableContent">
          <el-table
            :data="tableData"
            tooltip-effect="dark"
            border
            v-loading="loadingTable"
            ref="multipleTable"
            :max-height="tableHeight"
            :height="tableHeight"
            style="width: 100%"
          >
            <el-table-column
              label="序号"
              align="center"
              prop="shopId"
              width="80"
              type="index"
            />
            <el-table-column
              label="门店编号"
              align="center"
              prop="shopNo"
              min-width="150"
            />
            <el-table-column
              label="门店名称"
              align="center"
              prop="shopName"
              min-width="160"
            />
            <el-table-column
              label="门店自提"
              align="center"
              prop="billNo"
              min-width="100"
            >
              <template slot="header">
                <div class="x-fc">
                  <span class="marR10">门店自提</span>
                  <el-checkbox
                    :checked="isSelfPickup"
                    @change="changeIsSelfPickup"
                  />
                </div>
              </template>
              <template slot-scope="scope">
                <el-checkbox
                  v-model="scope.row.shopPickup"
                  @change="changeIsItemPickup(scope.row)"
                ></el-checkbox>
              </template>
            </el-table-column>
            <el-table-column
              label="同城配送"
              align="center"
              prop="billNo"
              min-width="100"
            >
              <template slot="header">
                <div class="x-fc">
                  <span class="marR10">同城配送</span>
                  <el-checkbox
                    :checked="isSelfCityDelivery"
                    @change="changeIsSelfCityDelivery"
                  />
                </div>
              </template>
              <template slot-scope="scope">
                <el-checkbox
                  v-model="scope.row.cityDelivery"
                  @change="changeIsItemCityDelivery(scope.row)"
                ></el-checkbox>
              </template>
            </el-table-column>
            <el-table-column
              label="快递配送"
              align="center"
              prop="billNo"
              min-width="100"
            >
              <template slot="header">
                <div class="x-fc">
                  <span class="marR10">快递配送</span>
                  <el-checkbox
                    :checked="isExpressDelivery"
                    @change="changeIsSelfExpressDelivery"
                  />
                </div>
              </template>
              <template slot-scope="scope">
                <el-checkbox
                  v-model="scope.row.expressDelivery"
                  @change="changeIsItemExpressDelivery(scope.row)"
                ></el-checkbox>
              </template>
            </el-table-column>
          </el-table>
        </div>
      </template>
    </cardTitleCom>
  </div>
</template>

<script>
import cardTitleCom from '@/views/components/cardTitleCom' //卡片标题
import {
  orderManageRightList,
  saveOrUpdate
} from '@/api/shop/orderCenter/orderManageRight' //设置
import selectAllFilter from '@/components/tablePage/select/select-all-filter/index.vue' //搜索
export default {
  name: 'OrderManageRight',
  components: {
    cardTitleCom,
    selectAllFilter
  },
  data () {
    return {
      //改变
      changeIdList: [],
      //缓存
      cacheList: [],
      //自提
      isSelfPickup: false,
      //同城
      isSelfCityDelivery: false,
      //快递
      isExpressDelivery: false,
      //自提半选
      isIndeterminateIsSelfPickupColumn: false,
      filters: [
        { filter: 'query', label: '全部' },
        { filter: 'shopNos', label: '门店编号' },
        { filter: 'shopNames', label: '门店名称' }
      ],
      loadingTable: false,
      tableData: [],
      total: 0,
      queryParams: { manageType: 2 },
      vivwH: 0 //页面高度
    }
  },
  created () {
    this.getList()
  },
  mounted () {
    this.$nextTick(() => {
      this.vivwH = document.getElementById('wrap')?.clientHeight
    })
    window.addEventListener('resize', this.handleResize)
  },
  beforeDestroy () {
    window.removeEventListener('resize', this.handleResize)
  },
  computed: {
    tableHeight () {
      return this.vivwH - 100
    }
  },
  methods: {
    async handleShop (tab, event) {
      await this.getList()
      //自提
      this.isSelfPickup = false
      //同城
      this.isSelfCityDelivery = false
      //快递
      this.isExpressDelivery = false
    },
    //判断全部是否勾选
    allOperations (type) {
      let selectEvery = false
      let selectSome = false
      switch (type) {
        case 'shopPickup':
          selectEvery = this.tableData.every(item => item.shopPickup)
          selectSome = this.tableData.some(item => item.shopPickup)
          //全部选择
          this.isSelfPickup = selectEvery
          //半选
          this.isIndeterminateIsSelfPickupColumn = !selectEvery && selectSome
          break
        default:
          break
      }
    },
    //门店自提
    changeIsItemPickup (row) {
      this.changeIdList.push(row.shopId)
    },
    //同城配送
    changeIsItemCityDelivery (row) {
      this.changeIdList.push(row.shopId)
    },
    //快递配送
    changeIsItemExpressDelivery (row) {
      this.changeIdList.push(row.shopId)
    },
    changeIsSelfPickup (e) {
      this.isSelfPickup = e
      this.changeIdList = []
      this.tableData = this.tableData?.map(item => {
        this.changeIdList.push(item.shopId)
        item.shopPickup = this.isSelfPickup
        return item
      })
    },
    changeIsSelfCityDelivery (e) {
      this.isSelfCityDelivery = e
      this.changeIdList = []
      this.tableData = this.tableData?.map(item => {
        this.changeIdList.push(item.shopId)
        item.cityDelivery = this.isSelfCityDelivery
        return item
      })
    },
    changeIsSelfExpressDelivery (e) {
      this.isExpressDelivery = e
      this.changeIdList = []
      this.tableData = this.tableData?.map(item => {
        this.changeIdList.push(item.shopId)
        item.expressDelivery = this.isExpressDelivery
        return item
      })
    },
    //保存
    saveAllData () {
      //过滤出改变的缓存数组
      const filteredCacheList = this.cacheList.filter(obj =>
        this.changeIdList?.some(v => v === obj?.shopId)
      )
      //过滤出缓存数组
      const filteredTableData = this.tableData.filter(obj =>
        this.changeIdList?.some(v => v === obj?.shopId)
      )
      const filteredArray = filteredTableData.filter(objA => {
        const objB = filteredCacheList.find(obj => obj.shopId === objA.shopId)
        if (objB) {
          const keys = Object.keys(objA)
          for (let i = 0; i < keys.length; i++) {
            const key = keys[i]
            if (key === 'fetchTypes') {
              if (!this.compareShopGoodsReps(objA[key], objB[key])) {
                return true
              }
            } else if (objA[key] !== objB[key]) {
              return true
            }
          }
          return false
        }
        return true
      })
      if (filteredArray?.length > 0) {
        const array = filteredArray.map(item => {
          item.fetchTypes = []
          if (item.shopPickup && !item.fetchTypes.includes(1)) {
            item.fetchTypes.push(1)
          }
          if (item.cityDelivery && !item.fetchTypes.includes(2)) {
            item.fetchTypes.push(2)
          }
          if (item.expressDelivery && !item.fetchTypes.includes(3)) {
            item.fetchTypes.push(3)
          }
          return {
            fetchTypes: item.fetchTypes,
            shopId: item.shopId,
            manageType: this.queryParams.manageType
          }
        })
        saveOrUpdate(array).then(response => {
          this.getList()
          this.$modal.msgSuccess('保存成功')
        })
      } else {
        this.$modal.msgSuccess('保存成功')
      }
    },
    //对比变化
    compareShopGoodsReps (arrA, arrB) {
      if (arrA.length !== arrB.length) {
        return false
      }
      for (let i = 0; i < arrA.length; i++) {
        const objA = arrA[i]
        const objB = arrB.find(obj => obj.shopId === objA.shopId)
        if (!objB) {
          return false
        }
        const keys = Object.keys(objA)
        for (let j = 0; j < keys.length; j++) {
          const key = keys[j]
          if (objA[key] !== objB[key]) {
            return false
          }
        }
      }
      return true
    },
    getList () {
      this.loadingTable = true
      orderManageRightList(this.queryParams).then(response => {
        response.data.forEach(item => {
          item.shopPickup = item.fetchTypes.includes(1)
          item.cityDelivery = item.fetchTypes.includes(2)
          item.expressDelivery = item.fetchTypes.includes(3)
        })
        this.tableData = response.data || []
        //缓存
        this.cacheList = JSON.parse(JSON.stringify(this.tableData))
        this.total = response.total
        this.loadingTable = false
      })
    },
    handleResize () {
      this.vivwH = document.getElementById('wrap')?.clientHeight
    }
  }
}
</script>

<style lang="scss" scoped>
.wrap {
  padding: 10px 10px 0 10px;
  background-color: #eaeaea;
  height: calc(100vh - 84px);
  .save {
    text-align: right;
  }
  .tableContent {
    margin: 6px 10px 10px 10px;
  }
}
</style>
