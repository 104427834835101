<template>
  <div>
    <!-- 二维码 -->
    <div id="verificationCode" ref="qrCodeDiv"></div>
    <div id="barcodeBox" v-show="false">
      <span>核销码: </span>
      <img ref="barcode" id="barcode" style="width: 100px; height: 100px" />
    </div>
  </div>
</template>

<script>
import QRCode from "qrcodejs2"; // 引入 QRCode
export default {
  data() {
    return {
      billId: undefined,
    };
  },
  methods: {
    // 生成核销二维码
    async generateBarcode(billId) {
      // const qrcode = document.createElement("div");
      // 获取用于显示二维码的元素
      const qrcode = document.getElementById("verificationCode");
      // 要编码的数据
      const data = billId;
      // 配置选项
      const options = {
        text: data, // 数据内容
        width: 100, // 宽度（像素）
        height: 100, // 高度（像素）
        colorDark: "#000000", // 前景色（黑色）
        colorLight: "#ffffff", // 背景色（白色）
      };
      // 创建二维码对象
      const qrCode = new QRCode(qrcode, options);
      // 生成二维码
      qrCode.makeCode(data);
      // 默认是PNG，也可以指定为"image/jpeg"并设置质量
      const imageURL = document.getElementsByTagName("canvas")[0].toDataURL("image/png");
      // 将图片显示在<img>标签中
      let img = document.getElementById("barcode");
      img.src = imageURL;
      await this.$nextTick();
      console.log("核销码111", data);
      const imageHtml = document.getElementById("barcodeBox").innerHTML;
      document.getElementById("verificationCode").innerHTML = "";
      return {
        imageURL,
        imageHtml,
      };
    },
  },
};
</script>
