<template>
  <div>
    <!-- 打印模板-收银小票 -->
    <div v-show="false">
      <div ref="receipt" id="receipt-content" style="font-size: 12px; vertical-align: middle; word-break: break-all"
        :style="{ width: `${getDpi(receiptFormat.formatMachineType)}px` }">
        <div class="edit-header" style="font-weight: bold; font-size: 20px; text-align: center; padding: 10px 0">
          {{ receiptFormat ? receiptFormat.formatHeadCaption : '' }}
        </div>

        <pre v-html="receiptFormat.formatContent" :style="{
            lineHeight: '16px',
            fontSize: '12px',
            whiteSpace: 'pre-wrap',
            verticalAlign: 'middle',
            fontFamily: 'Microsoft YaHei',
             wordWrap: 'break-word',
          }"></pre>

        <div class="edit-footer" style="word-break: break-all" v-show="receiptFormat.formatBottomCaption">
          {{ receiptFormat.formatBottomCaption }}
        </div>
      </div>
    </div>
    <!-- 厨打小票 -->
    <div class="kitchenPrint" id="kitchenPrint-content" v-show="false">
      <div ref="kitchenPrint" :style="{ width: `${getDpi(cookPrintFormat.formatMachineType)}px` }">
        <div style="font-size: 20px; text-align: center; padding: 10px 0;font-family:Microsoft YaHei">
          厨打小票
        </div>
        <pre v-html="cookPrintFormat.formatContent" :style="{
            lineHeight: '16px',
            fontSize: '12px',
            whiteSpace: 'pre-wrap',
            verticalAlign: 'middle',
            fontFamily: 'Microsoft YaHei',
            wordWrap: 'break-word',
          }"></pre>
      </div>
    </div>
    <!-- 配送小票 -->
    <div v-show="false">
      <div ref="delivery" id="delivery-content" :style="{ width: `${deliveryFormat.formatMachineType}'mm'` }">
        <!-- v-if="
            [1, 2, 3, 4, 5].includes(orderInfo.sourceOrderType) &&
            orderInfo.billOrderReq.fetchType == 2
          " -->
        <div>
          <pre v-html="deliveryFormat.formatContent" :style="{
              lineHeight: '16px',
              fontSize: '12px',
              whiteSpace: 'pre-wrap',
              verticalAlign: 'middle',
              fontFamily: 'Microsoft YaHei',
               wordWrap: 'break-word',
            }"></pre>
        </div>
      </div>
    </div>
    <!-- 订单打印小票 -->
    <div v-show="false">
      <div ref="orderPrintContent" class="orderPrint" id="orderPrint-content" :style="{ width:`${getDpi(orderPrintFormat.formatMachineType)}px` }">
         <div style="font-size: 20px; text-align: center; padding: 10px 0;font-family:Microsoft YaHei">
         {{orderInfo.orderTyprTitle }}
        </div>
        <pre v-html="orderPrintFormat.formatContent" :style="{
            lineHeight: '16px',
            fontSize: '12px',
            whiteSpace: 'pre-wrap',
            verticalAlign: 'middle',
            fontFamily: 'Microsoft YaHei',
             wordWrap: 'break-word',
          }"></pre>
      </div>
    </div>
       <!-- 核销码 -->
    <QRCode ref="QRCode" />
  </div>
</template>

<script>
import QRCode from "./QRCode.vue";
import {
 cookPrintDetailAPI
} from '@/api/shop/base/cookPrintCase' //厨打商品
import {
  getNoticeProduce
} from '@/api/shop/orderCenter/orderManage' //门店中台
import { vipParamListAPI } from "@/api/shop/setup/posParam/vipParam";
import {
  substringBetween, //字符串截取
  getFormattedDate, //获取时间
  fcount,
  concealStr
} from '@/utils'
import {
  getReceiptFormat //获取小票模板列表
} from '@/api/shop/setup/receiptFormat.js' //接口api
import {
  orderListAPI //门店订单中台列表
} from '@/api/shop/orderCenter/orderAutoPrintSet' //门店中台设置
import { cookPrintListAPI } from '@/api/shop/base/cookPrintCase' //厨打商品方案
// 局部引入
import {
  hiPrintPlugin,
  disAutoConnect, //取消自动连接直接打印客户端
  autoConnect,
  hiprint
} from 'vue-plugin-hiprint'
const getDpi = (num) => {
  const inch = document.createElement("div");
  inch.style.width = "1in";
  // 将div的尺寸设置为1英寸后，它会自动根据设备的分辨率进行缩放
  document.body.appendChild(inch);
  const dpi = inch.offsetWidth;
  document.body.removeChild(inch);
  const unit = Number(num-11);
  const inchs = unit / 25.4; // 将毫米转换为英寸,cm就除与2.54以此类推
  const px = inchs * dpi; // 将英寸转换为像素
  return Math.round(px); // 四舍五入取整数像素值
};
export default {
  name: 'OrderManageOrderPrint',
  components: { QRCode },
  props: {
    orderInfo: {
      typeof: Object,
      default:()=> {}
    },
    shopId: {
      typeof: [String,Number],
    },
  },
  data() {
    return {
      getDpi,
      shopParameter:{}, //门店参数
      receiptFormat: {
        formatId: undefined, //小票模板ID
        formatHeadCaption: '', //头部标题
        formatContent: '', //小票格式内容
        formatBottomCaption: '', //底部标语
        formatMachineType: 58, //小票规格类型
        formatLogoUrl: undefined, //Logo图片
        formatQrCodeUrl: undefined, //二维码图片
        isCurrentUseFormat: false, //是否当前使用模板
        isSysDefaultFormat: undefined //是否系统默认模板
      },
      //厨打小票模板信息
      cookPrintFormat: {
        formatMachineType: 58, //小票规格类型
        formatContent: '' //小票格式内容
      },
      //配送小票
      deliveryFormat: {
        formatMachineType: 58, //小票规格类型
        formatContent: '' //小票格式内容
      },
      //订单打印小票
      orderPrintFormat: {
        formatMachineType: 58, //小票规格类型
        formatContent: '' //小票格式内容
      },
      sourceOrderTypeEnumeration: {
        1: '自营商城',
        2: '美团订单',
        3: '饿了么订单',
        4: '有赞订单',
        5: '门店预订单'
      }, //订单来源
      deliveryModeTypeEnumeration: {
        0: '自营配送',
        1: '众包配送',
        2: '美团专送'
      }, //配送方式
      fetchTypeTypeEnumeration: {
        1: '门店自提',
        2: '同城配送',
        3: '快递配送'
      }, //取货方式
      //厨打设置
      kitchenList: []
    }
  },
   computed: {
    // 计算属性：将毫米转换为像素
    mmToPxWidth() {
      // 假设屏幕DPI为96，1英寸 = 25.4毫米
      const dpi = 96;
      const inchesPerMm = 1 / 25.4;
      return function (val){
        return val * inchesPerMm * dpi+'px';
      }
    },
  },
 async created() {
  const res = await vipParamListAPI();
  this.shopParameter = res.data;
   },
  methods: {
    printHiprint(printerName, refsName, formatMachineType) {
      // 判断是否已成功连接
      if (window.hiwebSocket.opened === false) {
        this.$notify.error('打印机客户端未连接')
        // 重新连接客户端  当然你可以去连接局域网内的客户端
        autoConnect() //自动连接直接打印客户端
        hiprint.hiwebSocket.setHost('http://localhost:17521')
        return
      }
      const hiprintTemplate = new hiprint.PrintTemplate()
      //浏览器
      // hiprintTemplate.printByHtml(this.$refs.delivery, {})
      //静默的打印
      let bodyhtm = this.$refs[refsName]
      hiprintTemplate.printByHtml2(bodyhtm, {
        // pageSize: {
        //   height: 'auto',
        //   width: formatMachineType * 1000
        // },
        printer: printerName, // 打印机名称，不填则默认打印机
        title: '打印'
      }) //直接打印，需要安装客户端
      console.log('打印结束')
    },
    //获取订单打印模板 并替换真实数据
    async getorderPrintTemplate(printerName) {
      return new Promise(async (resolve, reject) => {
        const res = await getReceiptFormat(
          '/api/system/mall/order/format/getSelected'
        )
        if (res?.data) {
          this.orderPrintFormat = res.data
          //小票内容区域json格式转为字符串
          this.orderPrintFormat.formatContent = JSON.parse(
            this.orderPrintFormat.formatContent
          ).ticketPrinterTemplate
        } else {
          this.$message.error('未设置后台订单打印模版！')
          return
        }
        //截取item中间的内容
        let goodsFieldStr = substringBetween(
          this.orderPrintFormat.formatContent,
          '#item',
          '#item'
        )
        let goodsStr = ''
        //替换商品数据
        if (goodsFieldStr) {
          let goodsList = this.orderInfo.billOrderListReserveDetailReqs
          goodsList.forEach(item => {
            let str = goodsFieldStr
              .replace(
                new RegExp('#{商品名称}', 'g'),
                `<span style="display:inline-block;">${item.goodsName}</span>`
              )
              .replace(
                new RegExp('#{单位}', 'g'),
                `<span style="display:inline-block;width:30px;textAlign:center">${item.unitName}</span>`
              )
              .replace(
                new RegExp('#{规格}', 'g'),
                `<span style="display:inline-block;width:30px;textAlign:center">${item.goodsSpec}</span>`
              )
              .replace(
                new RegExp('#{数量}', 'g'),
                `<span style="display:inline-block;width:30px;textAlign:center">${item.unitQty}</span>`
              )
              .replace(
                new RegExp('#{售价}', 'g'),
                `<span style="display:inline-block;width:30px;textAlign:center">${item.unitPrice}</span>`
              )
              .replace(
                new RegExp('#{金额}', 'g'),
                `<span style="display:inline-block;width:30px;textAlign:center">${item.unitMoney}</span>`
              )
              .replace(
                new RegExp('#{加值属性}', 'g'),
                `<span style="display:inline-block;width:30px;textAlign:center">${item.machining || ''
                }</span>`
              )
            goodsStr += str + '\n'
          })
        }
        //付款方式html字符串
        let payHtmlStr = "";
        let vipPayInfo = "";
        //预付款
        let advanceMoney = 0;
        this.orderInfo.billOrderListReservePayDetailReqs.forEach((item) => {
          advanceMoney = fcount([advanceMoney, item.payMoney], "+");
          payHtmlStr += `<div>${item.payModeName}: ${item.payMoney}</div>`;
          if (item.payModeId == 3 && !vipPayInfo) {
            vipPayInfo += '<div style="text-align:center;">会员卡付款单</div>';
            vipPayInfo += `<div>会员卡号: ${
              this.shopParameter.shop_VIP_CONFIG_STORED_31 == "Y"
                ? concealStr(item.vipNo)
                : item.vipNo
            }</div>`;
            vipPayInfo += `<div>会员姓名: ${
              this.shopParameter.shop_VIP_CONFIG_STORED_31 == "Y"
                ? concealStr(item.vipName, 1)
                : item.vipName || ""
            }</div>`;
            vipPayInfo += `<div>会员手机号: ${
              this.shopParameter.shop_VIP_CONFIG_STORED_31 == "Y"
                ? concealStr(item.tel)
                : item.tel || ""
            }</div>`;
            vipPayInfo += `<div>消费金额: ${item.payMoney}</div>`;
            vipPayInfo += `<div>卡内余额: ${
              item.vipBalance || item.totalUsableMoney
            }</div>`;
            vipPayInfo += `<div>本次积分: ${
              item.vipThisScore || item.lineScore || 0
            }</div>`;
            vipPayInfo += `<div>可用积分: ${
              item.vipUsableScore || item.usableScore
            }</div>`;
          }
        });
        //余款
        let spareMoney = fcount([this.orderInfo.billFactMoney, advanceMoney], "-");
        if (this.orderPrintFormat.formatContent) {
            // 生成二维码(核销码)
          const { imageURL, imageHtml } = await this.$refs.QRCode.generateBarcode(
            this.orderInfo.billId
          );
        this.orderInfo.sourceOrderType=Number(this.orderInfo.sourceOrderType)
          this.orderPrintFormat.formatContent =
            this.orderPrintFormat.formatContent
               .replace(new RegExp("#{订单号}", "g"), this.orderInfo.billNo)
            .replace(
              new RegExp("#{订单来源}", "g"),
              this.sourceOrderTypeEnumeration[this.orderInfo.sourceOrderType]
            )
            .replace(
              new RegExp("#{流水号}", "g"),
              this.orderInfo.billNo.substring(this.orderInfo.billNo.length - 5)
            )
            .replace(
              new RegExp("#{收货人}", "g"),
              this.orderInfo.billOrderReq?.receiverName || ""
            )
            .replace(new RegExp("#{门店电话}", "g"),  this.orderInfo.shopTel || "")
            .replace(
              new RegExp("#{预定门店}", "g"),
              this.orderInfo.billOrderReq.fetchShopName
            )
            .replace(
              new RegExp("#{配送时间}", "g"),
              (
                this.orderInfo.billOrderReq?.fetchDate?.split(" ")[0] +
                " " +
                this.orderInfo.billOrderReq.fetchTime
              ).replace("-", "/")
            )
            .replace(
              new RegExp("#{配送门店}", "g"),
              this.orderInfo.billOrderReq.fetchShopName
            )
            .replace(
              new RegExp("#{取货时间}", "g"),
              (
                this.orderInfo.billOrderReq.fetchDate.split(" ")[0] +
                " " +
                this.orderInfo.billOrderReq.fetchTime
              )
                .replace("-", "/")
                .replace("-", "/")
            )
            .replace(
              new RegExp("#{取货门店}", "g"),
              this.orderInfo.billOrderReq.fetchShopName
            )
            .replace(
              new RegExp("#{预约时间}", "g"),
              this.orderInfo.billDate.replace("-", "/").replace("-", "/")
            )
            .replace(
              new RegExp("#{打印时间}", "g"),
              getFormattedDate(4, "-").replace("-", "/").replace("-", "/")
            )
            .replace(new RegExp("#{备注}", "g"), this.orderInfo.billOrderReq.remark || "")
            .replace(new RegExp("#{餐台号}", "g"), this.orderInfo.tableNo || "")
            .replace(new RegExp("#{打印机名称}", "g"), printerName || "")
            .replace(
              new RegExp("#{手工单据号}", "g"),
              this.orderInfo.billOrderReq?.handBillNo || ""
            )
             .replace(
              new RegExp("#{磅数}", "g"),
              this.orderInfo.billOrderReq?.handPointSize || ""
            )
            .replace(
              new RegExp("#{尺寸}", "g"),
              this.orderInfo.billOrderReq?.handSize || ""
            )
            .replace(
              new RegExp("#{样式}", "g"),
              this.orderInfo.billOrderReq?.handStyle || ""
            )
            .replace(
              new RegExp("#{联系电话}", "g"),
              this.orderInfo.billOrderReq.receiverPhone
            )
            .replace(
              new RegExp("#{送货地址}", "g"),
              this.orderInfo.billOrderReq.receiverAddress || ""
            )
            .replace(
              new RegExp("#{配送方式}", "g"),
              this.deliveryModeTypeEnumeration[
                this.orderInfo.billOrderReq.deliveryMode
              ] || ""
            )
            .replace(
              new RegExp("#{取货方式}", "g"),
              this.fetchTypeTypeEnumeration[this.orderInfo.billOrderReq.fetchType] || ""
            )
            .replace(new RegExp("#{预付款}", "g"), advanceMoney || 0)
            .replace(new RegExp("#{余款}", "g"), spareMoney || 0)
            .replace(new RegExp("#{支付方式}", "g"), payHtmlStr)
            .replace(new RegExp("#{取餐码}", "g"), this.orderInfo.tableGuideNo || "")
             .replace(new RegExp("#{核销码}", "g"), imageHtml)
            .replace(new RegExp("/static/img/QRCode.80bb92a2.png", "g"), imageURL)
            .replace(new RegExp(`#item${goodsFieldStr}#item`, "g"), goodsStr);
             if (this.orderInfo.vipId && vipPayInfo) {
            this.orderPrintFormat.formatContent += vipPayInfo;
          }
          await this.printHiprint(
            printerName,
            'orderPrintContent',
            this.orderPrintFormat.formatMachineType
          )
        }
        resolve()
      })
    },
    //获取配送小票模板 并替换真实数据
    async getDeliveryTemplate(printerName) {
      return new Promise(async (resolve, reject) => {
        const res = await getReceiptFormat(
          '/api/system/shop/delivery/format/getSelected'
        )
        // console.log('模板', res)
        if (res.data) {
          this.deliveryFormat = res.data
          //小票内容区域json格式转为字符串
          this.deliveryFormat.formatContent = JSON.parse(
            this.deliveryFormat.formatContent
          ).ticketPrinterTemplate
        } else {
          this.$message.error('未设置后台配送模版！')
          return resolve(false)
        }

        //截取item中间的内容
        let goodsFieldStr = substringBetween(
          this.deliveryFormat.formatContent,
          '#item',
          '#item'
        )
        let goodsStr = ''
        //替换商品数据
        if (goodsFieldStr) {
          let goodsList = this.orderInfo.billOrderListReserveDetailReqs
          goodsList.forEach(item => {
            let str = goodsFieldStr
              .replace(
                new RegExp('#{商品名称}', 'g'),
                `<span style="display:inline-block;">${item.goodsName}</span>`
              )
              .replace(
                new RegExp('#{数量}', 'g'),
                `<span style="display:inline-block;width:30px;textAlign:center">${item.unitQty}</span>`
              )
              .replace(
                new RegExp('#{售价}', 'g'),
                `<span style="display:inline-block;width:30px;textAlign:center">${item.unitPrice}</span>`
              )
              .replace(
                new RegExp('#{金额}', 'g'),
                `<span style="display:inline-block;width:30px;textAlign:center">${item.unitMoney}</span>`
              )
              .replace(
                new RegExp('#{加值属性}', 'g'),
                `<span style="display:inline-block;width:30px;textAlign:center">${item.machining || ''
                }</span>`
              )
            goodsStr += str + '\n'
          })
        }

        //付款方式html字符串
        let payHtmlStr = ''
        this.orderInfo.billOrderListReservePayDetailReqs?.forEach(item => {
          payHtmlStr += `<div>${item.payModeName}: ${item.payMoney}</div>`
        })
        if (this.deliveryFormat.formatContent) {
        this.orderInfo.sourceOrderType=Number(this.orderInfo.sourceOrderType)
          this.deliveryFormat.formatContent = this.deliveryFormat.formatContent
            .replace(new RegExp('#{订单号}', 'g'), this.orderInfo.billNo)
            .replace(
              new RegExp('#{订单来源}', 'g'),
              this.sourceOrderTypeEnumeration[this.orderInfo.sourceOrderType]
            )
            .replace(
              new RegExp('#{配送时间}', 'g'),
              this.orderInfo.billOrderReq?.fetchDate?.split(' ')[0] +
              ' ' +
              this.orderInfo.billOrderReq.fetchTime
            )
            .replace(
              new RegExp('#{配送门店}', 'g'),
              this.orderInfo.billOrderReq.fetchShopName
            )
            .replace(new RegExp('#{下单时间}', 'g'), this.orderInfo.billDate)
            .replace(new RegExp('#{打印时间}', 'g'), getFormattedDate(4, '-'))
            .replace(
              new RegExp('#{备注}', 'g'),
              this.orderInfo.billOrderReq.remark || ''
            )
            .replace(
              new RegExp('#{收货人}', 'g'),
              this.orderInfo.billOrderReq.receiverName || ''
            )
            .replace(
              new RegExp('#{电话}', 'g'),
              this.orderInfo.billOrderReq.receiverPhone || ''
            )
            .replace(
              new RegExp('#{收货地址}', 'g'),
              this.orderInfo.billOrderReq.receiverAddress || ''
            )
            .replace(
              new RegExp('#{配送方式}', 'g'),
              this.deliveryModeTypeEnumeration[
              this.orderInfo.billOrderReq.deliveryMode
              ] || ''
            )
            .replace(new RegExp('#{支付方式}', 'g'), payHtmlStr)
            .replace(new RegExp('#{合计金额}', 'g'), this.orderInfo.billMoney)
            .replace(new RegExp('#{件数}', 'g'), this.orderInfo.billQty)
            .replace(
              new RegExp('#{配送费}', 'g'),
              this.orderInfo.deliveryFee || ''
            )
            .replace(new RegExp('#{打包费}', 'g'), '')
            .replace(
              new RegExp('#{商品规格}', 'g'),
              this.orderInfo.goodsSpec || ''
            )

            .replace(new RegExp(`#item${goodsFieldStr}#item`, 'g'), goodsStr)
        }
        await this.printHiprint(
          printerName,
          'delivery',
          this.deliveryFormat.formatMachineType
        )
        resolve(true)
        //lodop打印
        // this.$nextTick(async () => {
        //   let form1Str =
        //     '<body>' +
        //     document.getElementById('delivery-content').innerHTML +
        //     '</body>'
        //   //后台设置的大小
        //   let pageSize = this.deliveryFormat.formatMachineType * 10
        //   //类型、宽度、高度、文本、张数、收银机、是否开钱箱
        //   await printConfig('receipt', pageSize, '', form1Str, 1, printerName)
        // })
      })
    },
    //查询
    async handlePrintReceiptCook() {
      //厨打设置
      const res = await orderListAPI({
        pageNum: 1,
        pageSize: 99999999
      })
      this.kitchenList = res.rows || []
      //厨打打印机是否设置
      if (this.kitchenList.length > 0) {
        for (let i = 0; i < this.kitchenList.length; i++) {
          let item = this.kitchenList[i]
          if (
           item.printerName &&item.printCaseId &&item.printCount&&item.printCount>0
          ) {
             //拿到厨打过滤方案
            const res1 = await cookPrintDetailAPI(item.printCaseId)
            const printingScheme = res1.data||{}
            let usingInStoreType=  printingScheme.shopRangeType
            let shopIds= []
            if (["1", "2"].includes(printingScheme.shopRangeType)) {
                shopIds = printingScheme.shopCookPrintCaseShopReqs.map(
                  (item) => item.shopId
                );
              }
            for (let p = 0; p < item.printCount; p++) {
             let res2= await getNoticeProduce({
              shopId: this.shopId,
              state: 1, //1获取生产单2已打印
              usingInStoreType,
              shopIds,
              })
              let array = res2.data || []
              for (let j = 0; j < array.length; j++) {
                const productOrderText =JSON.parse(array[j].productOrderText);
                 //匹配适用商品
                let suitGoodsList = this.handleCookPrintCase(printingScheme,productOrderText)
                 console.log('准备打印厨打', productOrderText);
                //替换数据
               if (suitGoodsList.length > 0) {
                await this.getCookPrintTemplate(
                    suitGoodsList,
                    item.printerName,
                    productOrderText
                  )
                  await getNoticeProduce({
                  billId:array[j].billId,
                  state: 2, //1获取生产单2已打印
                  // remark:'重打',
                 })
                }
              }
            }
          }
        }
      }
    },
    //获取厨打小票模板 并替换真实数据
    async getCookPrintTemplate(suitGoodsList, printerName,productOrderText) {
      return new Promise(async (resolve, reject) => {
        const res = await getReceiptFormat(
          '/api/system/shop/cook/format/getSelected'
        )
        // console.log('模版', res.data)
        if (res.data) {
          this.cookPrintFormat = res.data
          //小票内容区域json格式转为字符串
          this.cookPrintFormat.formatContent = JSON.parse(
            this.cookPrintFormat.formatContent
          ).ticketPrinterTemplate
        } else {
          this.$message.error('未设置后台厨打模版！')
          return resolve(false)
        }
        //截取item中间的内容
        let goodsFieldStr = substringBetween(
          this.cookPrintFormat.formatContent,
          '#item',
          '#item'
        )
        let goodsStr = ''
        //替换商品数据
        if (goodsFieldStr) {
          suitGoodsList.forEach((item) => {
            let str = goodsFieldStr
              .replace(new RegExp("#{商品名称}", "g"), `${item.goodsName}`)
              .replace(new RegExp("#{加值属性}", "g"), `${item.machining || ""}`)

              .replace(new RegExp("#{规格}", "g"), `${item.goodsSpec || ""}`)
              .replace(new RegExp("#{数量}", "g"), `${item.unitQty}`);
            goodsStr += str + "\n";
          });
        }
        if (this.cookPrintFormat.formatContent) {
           // 生成二维码(核销码)
          const { imageURL, imageHtml } = await this.$refs.QRCode.generateBarcode(
            productOrderText.billId
          );
          productOrderText.sourceOrderType=Number(productOrderText.sourceOrderType)
          this.cookPrintFormat.formatContent = this.cookPrintFormat.formatContent
            .replace(new RegExp("#{打印机名称}", "g"), printerName)
            .replace(new RegExp("#{餐台号}", "g"), productOrderText.tableNo || "")
            .replace(
              new RegExp("#{备注}", "g"),
              productOrderText.billOrderReq?.remark || ""
            )
            .replace(new RegExp("#{下单时间}", "g"), productOrderText.billDate || "")
            .replace(
              new RegExp("#{订单来源}", "g"),
              productOrderText.sourceOrderType
                ? this.sourceOrderTypeEnumeration[productOrderText.sourceOrderType]
                : ""
            )
            .replace(new RegExp("#{打印时间}", "g"), getFormattedDate(4, "-") || "")
            .replace(new RegExp("#{流水号}", "g"), productOrderText.billNo || "")
            .replace(
              new RegExp("#{预约时间}", "g"),
              productOrderText.billOrderReq?.fetchDate
            )
            .replace(
              new RegExp("#{取货时间}", "g"),
              productOrderText.billOrderReq?.fetchType == 1
                ? productOrderText.billOrderReq?.fetchDate.replace(
                  new RegExp("00:00:00", "g"),
                  productOrderText.billOrderReq?.fetchTime
                )
                : ""
            )
            .replace(
              new RegExp("#{配送时间}", "g"),
              productOrderText.billOrderReq?.fetchType == 2
                ? productOrderText.billOrderReq?.deliveryDate.replace(
                  new RegExp("00:00:00", "g"),
                  productOrderText.billOrderReq?.deliveryTime
                )
                : ""
            )
            .replace(new RegExp("#{预订门店}", "g"), productOrderText?.shopName || "")
            .replace(new RegExp("#{订单号}", "g"), productOrderText.billNo)
              .replace(
              new RegExp("#{磅数}", "g"),
             productOrderText.billOrderReq?.handPointSize || ""
            )
            .replace(
              new RegExp("#{尺寸}", "g"),
             productOrderText.billOrderReq?.handSize || ""
            )
            .replace(
              new RegExp("#{样式}", "g"),
             productOrderText.billOrderReq?.handStyle || ""
            )
            .replace(
              new RegExp("#{收货人}", "g"),
              productOrderText.billOrderReq?.receiverName || ""
            )
            .replace(
              new RegExp("#{电话}", "g"),
              productOrderText.billOrderReq?.receiverPhone || ""
            )
            .replace(
              new RegExp("#{手工单据号}", "g"),
              productOrderText.billOrderReq?.handBillNo || ""
            )
            .replace(
              new RegExp("#{取货门店}", "g"),
              productOrderText.billOrderReq?.fetchShopName || ""
            )
            .replace(
              new RegExp("#{配送门店}", "g"),
              productOrderText.billOrderReq?.fetchShopName || ""
            )
            .replace(
              new RegExp("#{配送方式}", "g"),
              productOrderText.billOrderReq?.fetchType == 1
                ? "门店自提"
                : productOrderText.billOrderReq?.fetchType == 2
                  ? "商家配送"
                  : ""
            )
            .replace(
              new RegExp("#{配送地址}", "g"),
              productOrderText.billOrderReq?.receiverAddress || ""
            )
            .replace(new RegExp("#{取餐码}", "g"), productOrderText.tableGuideNo || "")
            .replace(new RegExp("#{核销码}", "g"), imageHtml)
            .replace(new RegExp("/static/img/QRCode.80bb92a2.png", "g"), imageURL)
            .replace(
              new RegExp("#{桌台号}", "g"),
              `<span style=" font-weight: bold;font-size: 18px;">${productOrderText.tableNo || ""
              }</span>`
            )
            .replace(
              new RegExp("#{桌台名称}", "g"),
              `<span style=" font-weight: bold;font-size: 18px;">${productOrderText.tableName || ""
              }</span>`
            )
            .replace(
              new RegExp("#{就餐方式}", "g"),
              productOrderText.mealType == 1
                ? "堂食"
                : productOrderText.mealType == 2
                  ? "外带"
                  : "正常"
            )
            .replace(new RegExp(`#item${goodsFieldStr}#item`, "g"), goodsStr);
           if (productOrderText.type=='重打') {
            this.cookPrintFormat.formatContent =
              this.cookPrintFormat.formatContent +
              `<div style="font-size:30px;text-align:center;margin:10px 0">${productOrderText.type}</div>`;
          }
        }
        console.log('准备打印厨打2', printerName, this.cookPrintFormat.formatMachineType)
        await this.printHiprint(
          printerName,
          'kitchenPrint',
          this.cookPrintFormat.formatMachineType
        )
        resolve(true)
      })
    },
    //处理厨打方案 过滤不适用商品
    handleCookPrintCase(scheme,productOrderText) {
      let goodsList = []
      console.log('准备orderInfo', productOrderText);
      console.log('准备scheme', scheme);
      if (scheme.goodsRangeType == "1" || scheme.goodsRangeType == "2") {
        productOrderText.billOrderListReserveDetailReqs.forEach((item) => {
          let bool = scheme.shopCookPrintCaseGoodsReqs.find((i) => {
            switch (i.lineType) {
              case 1: //商品
                return i.lineId == item.goodsId && i.unitId == item.unitId;
              case 2: //品牌
                return i.lineId == item.brandId;
              case 3: //类别
                return i.lineId == item.categoryId;
              default:
                break;
            }
          });
          if (
            (!bool && scheme.goodsRangeType == "1") ||
            (bool && scheme.goodsRangeType == "2")
          ) {
            goodsList.push({ ...item });
          }
        });
      } else if (scheme.goodsRangeType == "0") {
        goodsList = productOrderText.billOrderListReserveDetailReqs;
      }
      return goodsList || []
    },
    //获取收银小票模板 并替换真实数据
    async getReceiptTemplate(printerName) {
      return new Promise(async (resolve, reject) => {
        const res = await getReceiptFormat(
          '/api/system/shop/format/getSelected'
        )
        if (res.data) {
          this.receiptFormat = res.data
          //小票内容区域json格式转为字符串
          this.receiptFormat.formatContent = JSON.parse(
            this.receiptFormat.formatContent
          ).ticketPrinterTemplate
        } else {
          this.$message.error('未设置后台小票模版！')
          return resolve(false)
        }
        //截取item中间的内容
        let goodsFieldStr = substringBetween(
          this.receiptFormat.formatContent,
          '#item',
          '#item'
        )
        let goodsStr = ''
        //替换商品数据
        if (goodsFieldStr) {
          let goodsList
          if (this.orderInfo.isRetailOrder) {
            //零售单数据
            goodsList = this.orderInfo.middleGroundBillOrderGoodsInfoResps
          } else {
            //预订单数据
            goodsList = this.orderInfo.billOrderListReserveDetailReqs
          }
          console.log('goodsList', goodsList)
          goodsList.forEach(item => {
            let str = goodsFieldStr
              .replace(
                new RegExp('#{商品名称}', 'g'),
                `<span style="display:inline-block;">${item.goodsName}</span>`
              )
              .replace(
                new RegExp('#{商品编码}', 'g'),
                `<span style="display:inline-block;>${item.goodsNo}</span>`
              )
              .replace(
                new RegExp('#{条码}', 'g'),
                `<span style="display:inline-block;">${item.barcode}</span>`
              )
              .replace(
                new RegExp('#{单位}', 'g'),
                `<span style="display:inline-block;width:30px;textAlign:center">${item.unitName || ''
                }</span>`
              )
              .replace(
                new RegExp('#{数量}', 'g'),
                `<span style="display:inline-block;width:30px;textAlign:center">${item.unitQty || ''
                }</span>`
              )
              .replace(
                new RegExp('#{单价}', 'g'),
                `<span style="display:inline-block;width:30px;textAlign:center">${item.salePrice || ''
                }</span>`
              )
              .replace(
                new RegExp('#{售价}', 'g'),
                `<span style="display:inline-block;width:30px;textAlign:center">${item.unitPrice || ''
                }</span>`
              )
              .replace(
                new RegExp('#{金额}', 'g'),
                `<span style="display:inline-block;width:30px;textAlign:center">${item.unitMoney || ''
                }</span>`
              )
            goodsStr += str + '\n'
          })
        }
        //付款数组字段
        let payArrField = undefined
        if (this.orderInfo.isRetailOrder) {
          //零售单数据
          payArrField = 'shopBillPosRetailPayDetailReq'
        } else {
          //预订单数据
          payArrField = 'billOrderListReservePayDetailReqs'
        }
        //付款方式html字符串
        let payHtmlStr = ''
        let vipPayInfo = '' //会员付款信息
        this.orderInfo[payArrField].forEach(item => {
          payHtmlStr += `<div>${item.payModeName || ''}: ${item.payMoney || ''
            }</div>`
          if (item.payModeId == 3 && !vipPayInfo) {
            vipPayInfo += '<div style="text-align:center">会员卡付款单</div>'
            vipPayInfo += `<div>会员卡号: ${item?.vipNo || ''}</div>`
            vipPayInfo += `<div>消费金额: ${item?.payMoney || ''}</div>`
            vipPayInfo += `<div>卡内余额: ${item?.vipBalance || ''}</div>`
            vipPayInfo += `<div>本次积分: ${item?.vipThisScore || ''}</div>`
            vipPayInfo += `<div>可用积分: ${item?.vipUsableScore || ''}</div>`
          }
        })
        console.log('orderInfo', this.orderInfo)
        console.log(
          'billOrderListReservePayDetailReqs',
          this.orderInfo[payArrField]
        )
        if (this.receiptFormat.formatContent) {
          this.receiptFormat.formatContent = this.receiptFormat.formatContent
            .replace(
              new RegExp('#{会员号}', 'g'),
              this.orderInfo[payArrField][0]?.vipNo || ''
            )
            .replace(
              new RegExp('#{会员卡本次消费金额}', 'g'),
              this.orderInfo[payArrField][0]?.payMoney || ''
            )
            .replace(
              new RegExp('#{消费后余额}', 'g'),
              this.orderInfo[payArrField][0]?.vipBalance || ''
            )
            .replace(
              new RegExp('#{本次积分}', 'g'),
              this.orderInfo[payArrField][0]?.vipThisScore || ''
            )
            .replace(
              new RegExp('#{积分}', 'g'),
              this.orderInfo[payArrField][0]?.vipUsableScore || ''
            )
            .replace(
              new RegExp('#{门店名称}', 'g'),
              this.orderInfo.shopName || ''
            )
            .replace(
              new RegExp('#{门店地址}', 'g'),
              this.orderInfo.shopAddress || ''
            )
            .replace(
              new RegExp('#{联系电话}', 'g'),
              this.orderInfo.linkEmployeeTel || ''
            )
            .replace(
              new RegExp('#{收银员姓名}', 'g'),
              this.orderInfo.cashierName || ''
            )
            .replace(
              new RegExp('#{收银员编号}', 'g'),
              this.orderInfo.cashierNo || ''
            )
            .replace(
              new RegExp('#{导购员}', 'g'),
              this.orderInfo.guideManName || ''
            )
            .replace(
              new RegExp('#{导购员编号}', 'g'),
              this.orderInfo.guideManNo || ''
            )
            .replace(new RegExp('#{小票号}', 'g'), this.orderInfo.billNo)
            .replace(new RegExp('#{支付方式}', 'g'), payHtmlStr)
            .replace(new RegExp('#{总计}', 'g'), this.orderInfo.billMoney || '')
            .replace(new RegExp('#{件数}', 'g'), this.orderInfo.billQty || '')
            .replace(new RegExp('#{应收}', 'g'), this.orderInfo.billMoney || '')
            .replace(
              new RegExp('#{收款}', 'g'),
              this.orderInfo.receivableMoney || ''
            )
            .replace(new RegExp('#{整单折扣}', 'g'), '')
            .replace(
              new RegExp('#{实收}', 'g'),
              this.orderInfo.billFactMoney || ''
            )
            .replace(new RegExp('#{找零}', 'g'), this.orderInfo.giveChange)
            .replace(
              new RegExp('#{优惠}', 'g'),
              this.orderInfo.billDiscountsMoney || ''
            )
            .replace(new RegExp('#{日期时间}', 'g'), this.orderInfo.billDate)
            .replace(new RegExp(`#item${goodsFieldStr}#item`, 'g'), goodsStr)
            .replace(
              '#IMG{图片1}',
              `<img style="width:120px;height:120px" src="${this.receiptFormat.formatLogoUrl}"/>`
            )
            .replace(
              '#IMG{图片2}',
              `<img style="width:200px;height:200px" src="${this.receiptFormat.formatQrCodeUrl}"/>`
            )
          this.receiptFormat.formatContent += vipPayInfo
        }
        await this.printHiprint(
          printerName,
          'receipt',
          this.receiptFormat.formatMachineType
        )
        console.log('打印成功')
        resolve(true)
        // this.$nextTick(async () => {
        //   let form1Str =
        //     '<body>' +
        //     document.getElementById('receipt-content').innerHTML +
        //     '</body>'
        //   //后台设置的大小
        //   let pageSize = this.receiptFormat.formatMachineType * 10
        //   //类型、宽度、高度、文本、张数、收银机、是否开钱箱
        //   await printConfig('receipt', pageSize, '', form1Str, 1, printerName)
        // })
      })
    }
  }
}
</script>

<style lang="scss" scoped></style>
