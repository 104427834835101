var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "wrap", attrs: { id: "wrap" } },
    [
      _c(
        "div",
        { staticClass: "save" },
        [
          _c(
            "el-button",
            {
              staticClass: "marR10 marB5",
              attrs: { type: "primary", size: "mini" },
              on: { click: _vm.saveAllData },
            },
            [_vm._v("保存")]
          ),
        ],
        1
      ),
      _c(
        "cardTitleCom",
        {
          attrs: { cardTitle: "订单管理设置", id: "search-card" },
          scopedSlots: _vm._u([
            {
              key: "leftCardTitle",
              fn: function () {
                return [
                  _c(
                    "el-radio-group",
                    {
                      attrs: { size: "mini" },
                      on: { change: _vm.handleShop },
                      model: {
                        value: _vm.queryParams.manageType,
                        callback: function ($$v) {
                          _vm.$set(_vm.queryParams, "manageType", $$v)
                        },
                        expression: "queryParams.manageType",
                      },
                    },
                    [
                      _c("el-radio-button", { attrs: { label: 2 } }, [
                        _vm._v("门店订单管理"),
                      ]),
                      _c("el-radio-button", { attrs: { label: 1 } }, [
                        _vm._v("总部订单管理"),
                      ]),
                    ],
                    1
                  ),
                ]
              },
              proxy: true,
            },
            {
              key: "rightCardTitle",
              fn: function () {
                return [
                  _c("selectAllFilter", {
                    attrs: { filters: _vm.filters },
                    model: {
                      value: _vm.queryParams,
                      callback: function ($$v) {
                        _vm.queryParams = $$v
                      },
                      expression: "queryParams",
                    },
                  }),
                  _c(
                    "el-button",
                    {
                      staticClass: "marL10 marR10",
                      attrs: { type: "primary", size: "mini" },
                      on: { click: _vm.getList },
                    },
                    [_vm._v("查询")]
                  ),
                ]
              },
              proxy: true,
            },
          ]),
        },
        [
          _c("template", { slot: "cardContent" }, [
            _c(
              "div",
              { staticClass: "tableContent" },
              [
                _c(
                  "el-table",
                  {
                    directives: [
                      {
                        name: "loading",
                        rawName: "v-loading",
                        value: _vm.loadingTable,
                        expression: "loadingTable",
                      },
                    ],
                    ref: "multipleTable",
                    staticStyle: { width: "100%" },
                    attrs: {
                      data: _vm.tableData,
                      "tooltip-effect": "dark",
                      border: "",
                      "max-height": _vm.tableHeight,
                      height: _vm.tableHeight,
                    },
                  },
                  [
                    _c("el-table-column", {
                      attrs: {
                        label: "序号",
                        align: "center",
                        prop: "shopId",
                        width: "80",
                        type: "index",
                      },
                    }),
                    _c("el-table-column", {
                      attrs: {
                        label: "门店编号",
                        align: "center",
                        prop: "shopNo",
                        "min-width": "150",
                      },
                    }),
                    _c("el-table-column", {
                      attrs: {
                        label: "门店名称",
                        align: "center",
                        prop: "shopName",
                        "min-width": "160",
                      },
                    }),
                    _c(
                      "el-table-column",
                      {
                        attrs: {
                          label: "门店自提",
                          align: "center",
                          prop: "billNo",
                          "min-width": "100",
                        },
                        scopedSlots: _vm._u([
                          {
                            key: "default",
                            fn: function (scope) {
                              return [
                                _c("el-checkbox", {
                                  on: {
                                    change: function ($event) {
                                      return _vm.changeIsItemPickup(scope.row)
                                    },
                                  },
                                  model: {
                                    value: scope.row.shopPickup,
                                    callback: function ($$v) {
                                      _vm.$set(scope.row, "shopPickup", $$v)
                                    },
                                    expression: "scope.row.shopPickup",
                                  },
                                }),
                              ]
                            },
                          },
                        ]),
                      },
                      [
                        _c("template", { slot: "header" }, [
                          _c(
                            "div",
                            { staticClass: "x-fc" },
                            [
                              _c("span", { staticClass: "marR10" }, [
                                _vm._v("门店自提"),
                              ]),
                              _c("el-checkbox", {
                                attrs: { checked: _vm.isSelfPickup },
                                on: { change: _vm.changeIsSelfPickup },
                              }),
                            ],
                            1
                          ),
                        ]),
                      ],
                      2
                    ),
                    _c(
                      "el-table-column",
                      {
                        attrs: {
                          label: "同城配送",
                          align: "center",
                          prop: "billNo",
                          "min-width": "100",
                        },
                        scopedSlots: _vm._u([
                          {
                            key: "default",
                            fn: function (scope) {
                              return [
                                _c("el-checkbox", {
                                  on: {
                                    change: function ($event) {
                                      return _vm.changeIsItemCityDelivery(
                                        scope.row
                                      )
                                    },
                                  },
                                  model: {
                                    value: scope.row.cityDelivery,
                                    callback: function ($$v) {
                                      _vm.$set(scope.row, "cityDelivery", $$v)
                                    },
                                    expression: "scope.row.cityDelivery",
                                  },
                                }),
                              ]
                            },
                          },
                        ]),
                      },
                      [
                        _c("template", { slot: "header" }, [
                          _c(
                            "div",
                            { staticClass: "x-fc" },
                            [
                              _c("span", { staticClass: "marR10" }, [
                                _vm._v("同城配送"),
                              ]),
                              _c("el-checkbox", {
                                attrs: { checked: _vm.isSelfCityDelivery },
                                on: { change: _vm.changeIsSelfCityDelivery },
                              }),
                            ],
                            1
                          ),
                        ]),
                      ],
                      2
                    ),
                    _c(
                      "el-table-column",
                      {
                        attrs: {
                          label: "快递配送",
                          align: "center",
                          prop: "billNo",
                          "min-width": "100",
                        },
                        scopedSlots: _vm._u([
                          {
                            key: "default",
                            fn: function (scope) {
                              return [
                                _c("el-checkbox", {
                                  on: {
                                    change: function ($event) {
                                      return _vm.changeIsItemExpressDelivery(
                                        scope.row
                                      )
                                    },
                                  },
                                  model: {
                                    value: scope.row.expressDelivery,
                                    callback: function ($$v) {
                                      _vm.$set(
                                        scope.row,
                                        "expressDelivery",
                                        $$v
                                      )
                                    },
                                    expression: "scope.row.expressDelivery",
                                  },
                                }),
                              ]
                            },
                          },
                        ]),
                      },
                      [
                        _c("template", { slot: "header" }, [
                          _c(
                            "div",
                            { staticClass: "x-fc" },
                            [
                              _c("span", { staticClass: "marR10" }, [
                                _vm._v("快递配送"),
                              ]),
                              _c("el-checkbox", {
                                attrs: { checked: _vm.isExpressDelivery },
                                on: { change: _vm.changeIsSelfExpressDelivery },
                              }),
                            ],
                            1
                          ),
                        ]),
                      ],
                      2
                    ),
                  ],
                  1
                ),
              ],
              1
            ),
          ]),
        ],
        2
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }