var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c(
        "div",
        {
          directives: [
            {
              name: "show",
              rawName: "v-show",
              value: false,
              expression: "false",
            },
          ],
        },
        [
          _c(
            "div",
            {
              ref: "receipt",
              staticStyle: {
                "font-size": "12px",
                "vertical-align": "middle",
                "word-break": "break-all",
              },
              style: {
                width: _vm.getDpi(_vm.receiptFormat.formatMachineType) + "px",
              },
              attrs: { id: "receipt-content" },
            },
            [
              _c(
                "div",
                {
                  staticClass: "edit-header",
                  staticStyle: {
                    "font-weight": "bold",
                    "font-size": "20px",
                    "text-align": "center",
                    padding: "10px 0",
                  },
                },
                [
                  _vm._v(
                    " " +
                      _vm._s(
                        _vm.receiptFormat
                          ? _vm.receiptFormat.formatHeadCaption
                          : ""
                      ) +
                      " "
                  ),
                ]
              ),
              _c("pre", {
                style: {
                  lineHeight: "16px",
                  fontSize: "12px",
                  whiteSpace: "pre-wrap",
                  verticalAlign: "middle",
                  fontFamily: "Microsoft YaHei",
                  wordWrap: "break-word",
                },
                domProps: {
                  innerHTML: _vm._s(_vm.receiptFormat.formatContent),
                },
              }),
              _c(
                "div",
                {
                  directives: [
                    {
                      name: "show",
                      rawName: "v-show",
                      value: _vm.receiptFormat.formatBottomCaption,
                      expression: "receiptFormat.formatBottomCaption",
                    },
                  ],
                  staticClass: "edit-footer",
                  staticStyle: { "word-break": "break-all" },
                },
                [
                  _vm._v(
                    " " + _vm._s(_vm.receiptFormat.formatBottomCaption) + " "
                  ),
                ]
              ),
            ]
          ),
        ]
      ),
      _c(
        "div",
        {
          directives: [
            {
              name: "show",
              rawName: "v-show",
              value: false,
              expression: "false",
            },
          ],
          staticClass: "kitchenPrint",
          attrs: { id: "kitchenPrint-content" },
        },
        [
          _c(
            "div",
            {
              ref: "kitchenPrint",
              style: {
                width: _vm.getDpi(_vm.cookPrintFormat.formatMachineType) + "px",
              },
            },
            [
              _c(
                "div",
                {
                  staticStyle: {
                    "font-size": "20px",
                    "text-align": "center",
                    padding: "10px 0",
                    "font-family": "Microsoft YaHei",
                  },
                },
                [_vm._v(" 厨打小票 ")]
              ),
              _c("pre", {
                style: {
                  lineHeight: "16px",
                  fontSize: "12px",
                  whiteSpace: "pre-wrap",
                  verticalAlign: "middle",
                  fontFamily: "Microsoft YaHei",
                  wordWrap: "break-word",
                },
                domProps: {
                  innerHTML: _vm._s(_vm.cookPrintFormat.formatContent),
                },
              }),
            ]
          ),
        ]
      ),
      _c(
        "div",
        {
          directives: [
            {
              name: "show",
              rawName: "v-show",
              value: false,
              expression: "false",
            },
          ],
        },
        [
          _c(
            "div",
            {
              ref: "delivery",
              style: { width: _vm.deliveryFormat.formatMachineType + "'mm'" },
              attrs: { id: "delivery-content" },
            },
            [
              _c("div", [
                _c("pre", {
                  style: {
                    lineHeight: "16px",
                    fontSize: "12px",
                    whiteSpace: "pre-wrap",
                    verticalAlign: "middle",
                    fontFamily: "Microsoft YaHei",
                    wordWrap: "break-word",
                  },
                  domProps: {
                    innerHTML: _vm._s(_vm.deliveryFormat.formatContent),
                  },
                }),
              ]),
            ]
          ),
        ]
      ),
      _c(
        "div",
        {
          directives: [
            {
              name: "show",
              rawName: "v-show",
              value: false,
              expression: "false",
            },
          ],
        },
        [
          _c(
            "div",
            {
              ref: "orderPrintContent",
              staticClass: "orderPrint",
              style: {
                width:
                  _vm.getDpi(_vm.orderPrintFormat.formatMachineType) + "px",
              },
              attrs: { id: "orderPrint-content" },
            },
            [
              _c(
                "div",
                {
                  staticStyle: {
                    "font-size": "20px",
                    "text-align": "center",
                    padding: "10px 0",
                    "font-family": "Microsoft YaHei",
                  },
                },
                [_vm._v(" " + _vm._s(_vm.orderInfo.orderTyprTitle) + " ")]
              ),
              _c("pre", {
                style: {
                  lineHeight: "16px",
                  fontSize: "12px",
                  whiteSpace: "pre-wrap",
                  verticalAlign: "middle",
                  fontFamily: "Microsoft YaHei",
                  wordWrap: "break-word",
                },
                domProps: {
                  innerHTML: _vm._s(_vm.orderPrintFormat.formatContent),
                },
              }),
            ]
          ),
        ]
      ),
      _c("QRCode", { ref: "QRCode" }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }