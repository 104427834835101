var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "wrapIndex" },
    [
      _c(
        "cardTitleCom",
        { attrs: { cardTitle: "开通营业通" } },
        [
          _c("template", { slot: "cardContent" }, [
            _c(
              "div",
              {
                staticClass: "topImg",
                style:
                  "background-image:url(" +
                  require("@/assets/images/goBuyActivate-topBack.png") +
                  ");",
              },
              [
                _c("div", { staticClass: "topText" }, [
                  _c("div", { staticClass: "title22" }, [_vm._v("老板的烦恼")]),
                  _c("div", [
                    _vm._v(
                      " 1.老板经常出差在外，迫切希望能掌控店里的销售汇总情况，利润跟踪，需要不停打电话询问，麻烦。 "
                    ),
                  ]),
                  _c("div", [
                    _vm._v("2.打开笔记本、台式电脑查看各大报表汇总？不方便。"),
                  ]),
                  _c("div", [
                    _vm._v(
                      "3.老板要亲自去操做ERP软件才能看到报表，哪有这个时间。"
                    ),
                  ]),
                  _c("div", [
                    _vm._v(
                      " 4.老板要等着员工发来短信、邮件报告才知道销售状况，信息传递太滞后。 "
                    ),
                  ]),
                ]),
              ]
            ),
            _c(
              "div",
              { staticClass: "topContent" },
              [
                _c("div", { staticClass: "text" }, [
                  _c(
                    "div",
                    { staticClass: "text-content" },
                    [
                      _c("el-image", {
                        staticClass: "icon",
                        attrs: {
                          src: require("@/assets/images/goBuyActivate-icon1.png"),
                        },
                      }),
                      _c(
                        "div",
                        { staticClass: "left-text" },
                        [
                          _c("el-image", {
                            staticClass: "icon",
                            attrs: {
                              src: require("@/assets/images/goBuyActivate-text1.png"),
                            },
                          }),
                          _c("div", [
                            _vm._v(
                              " 营业通可以随时查看门店的销售、库存、客流等数据，及时掌握门店的运营状况，做出有效的决策。 "
                            ),
                          ]),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                  _c(
                    "div",
                    { staticClass: "text-content" },
                    [
                      _c("el-image", {
                        staticClass: "icon",
                        attrs: {
                          src: require("@/assets/images/goBuyActivate-icon2.png"),
                        },
                      }),
                      _c(
                        "div",
                        { staticClass: "left-text" },
                        [
                          _c("el-image", {
                            staticClass: "icon",
                            attrs: {
                              src: require("@/assets/images/goBuyActivate-text2.png"),
                            },
                          }),
                          _c("div", [
                            _vm._v(
                              " 营业通可以自定义报表的内容、格式、时间范围等，根据需要展示不同的数据维度，满足个性化需求。 "
                            ),
                          ]),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                  _c(
                    "div",
                    { staticClass: "text-content" },
                    [
                      _c("el-image", {
                        staticClass: "icon",
                        attrs: {
                          src: require("@/assets/images/goBuyActivate-icon3.png"),
                        },
                      }),
                      _c(
                        "div",
                        { staticClass: "left-text" },
                        [
                          _c("el-image", {
                            staticClass: "icon",
                            attrs: {
                              src: require("@/assets/images/goBuyActivate-text3.png"),
                            },
                          }),
                          _c("div", [
                            _vm._v(
                              " 营业通可以将数据以图表等形式呈现，一目了然，轻松分析数据的趋势、比较、分布等，提高数据的直观性和可用性。 "
                            ),
                          ]),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                  _c(
                    "div",
                    { staticClass: "text-content" },
                    [
                      _c("el-image", {
                        staticClass: "icon",
                        attrs: {
                          src: require("@/assets/images/goBuyActivate-icon4.png"),
                        },
                      }),
                      _c(
                        "div",
                        { staticClass: "left-text" },
                        [
                          _c("el-image", {
                            staticClass: "icon",
                            attrs: {
                              src: require("@/assets/images/goBuyActivate-text4.png"),
                            },
                          }),
                          _c("div", [
                            _vm._v(
                              " 营业通可以在任何地点、任何时间，通过手机访问门店的数据，无需依赖电脑，提高工作效率和灵活性。 "
                            ),
                          ]),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                  _c(
                    "div",
                    { staticClass: "text-content" },
                    [
                      _c("el-image", {
                        staticClass: "icon",
                        attrs: {
                          src: require("@/assets/images/goBuyActivate-icon5.png"),
                        },
                      }),
                      _c(
                        "div",
                        { staticClass: "left-text" },
                        [
                          _c("el-image", {
                            staticClass: "icon",
                            attrs: {
                              src: require("@/assets/images/goBuyActivate-text5.png"),
                            },
                          }),
                          _c("div", [
                            _vm._v(
                              " 营业通可以通过简单的操作，快速生成和查看报表，无需复杂的设置和操作，节省时间和精力。 "
                            ),
                          ]),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ]),
                _c("el-image", {
                  staticClass: "img",
                  attrs: {
                    src: require("@/assets/images/goBuyActivate-phone.png"),
                  },
                }),
              ],
              1
            ),
            _c(
              "div",
              { staticClass: "buttons" },
              [
                _c("div", { staticClass: "button", on: { click: _vm.goBuy } }, [
                  _vm._v(
                    " " +
                      _vm._s(_vm.price) +
                      " " +
                      _vm._s(_vm.isBuy == "buy" ? "立即开通" : "立即续费") +
                      " "
                  ),
                ]),
                _c(
                  "el-link",
                  {
                    staticClass: "text-button",
                    attrs: { type: "primary" },
                    on: { click: _vm.goList },
                  },
                  [_vm._v("查询订单列表>")]
                ),
              ],
              1
            ),
          ]),
        ],
        2
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }