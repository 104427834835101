<template>
  <div class="wrapList">
    <TablePage ref="tablePage" v-model="options" @handleEvent="handleEvent">
      <template slot="product" slot-scope="{ row }" v-if="row">
        <div style="font-size: 12px">
          <div>产品：{{ row.productName }}</div>
          <div>商户：{{ row.tenantName }}</div>
          <div>计费模式：{{ row.productCalcMode }}</div>
          <div>
            <div>门店数：{{ row.shops }}个</div>
            <div class="text-left">单价：{{ row.shopsOldPrice }}元</div>
            <div class="text-left">折扣：{{ row.shopsDiscount }}元</div>
            <div class="text-left">实付金额：{{ row.shopsPrice }}元</div>
          </div>
          <div>
            <div>用户数数：{{ row.users }}个</div>
            <div class="text-left">单价：{{ row.usersOldPrice }}元</div>
            <div class="text-left">折扣：{{ row.usersDiscount }}元</div>
            <div class="text-left">实付金额：{{ row.usersPrice }}元</div>
          </div>
          <div>合计：{{ row.orderPayMoney }}</div>
        </div>
      </template>
    </TablePage>
  </div>
</template>
<script>
import {
  getTradeProductOrder,
  getOrderDetail
} from '@/api/shop/mobileQueryApp/index.js'
import TablePage from '@/components/tablePage'
export default {
  name: 'buyList',
  components: { TablePage },
  data () {
    return {
      options: {
        loading: true,
        radioSelect: true,
        check: [],
        pagination: {
          total: 0,
          page: 1,
          size: 15
        },
        title: '订单列表',
        listNo: true, // 序号
        check: [], // 选中数据
        rowKey: 'orderNo',
        height: 550,
        delHeight: 37,
        getListApi: getTradeProductOrder,
        defaultBody: async () => {
          const { data } = await getOrderDetail()
          return { productId: data.productId }
        },
        buttons: [
          {
            click: 'pay',
            label: '支付',
            type: 'primary'
          },
          {
            right: true,
            click: 'back',
            label: '返回'
          }
        ],
        columns: [
          {
            prop: 'tenantName',
            label: '商户名称',
            minWidth: 160,
            align: 'center'
          },
          {
            prop: 'orderNo',
            label: '订单号',
            minWidth: 250,
            align: 'center'
          },
          {
            prop: 'createTime',
            label: '订单时间',
            minWidth: 155,
            align: 'center'
          },
          {
            prop: 'payStatusName',
            label: '支付状态',
            minWidth: 120,
            align: 'center'
          },
          {
            prop: 'linkMan',
            label: '联系人',
            minWidth: 120,
            align: 'center'
          },
          {
            prop: 'linkManTel',
            label: '联系电话',
            minWidth: 110,
            align: 'center'
          },
          {
            prop: 'product',
            label: '产品',
            minWidth: 220,
            type: 'slot',
            align: 'left'
          },
          {
            prop: 'orderPayMoney',
            label: '实付金额',
            minWidth: 80,
            align: 'center'
          },
          {
            prop: 'orderOldMoney',
            label: '原价金额',
            minWidth: 80,
            align: 'center'
          },
          {
            prop: 'orderDiscountMoney',
            label: '优惠金额',
            minWidth: 80,
            align: 'center'
          },
          {
            prop: 'orderPayModeName',
            label: '支付方式',
            minWidth: 100,
            align: 'center'
          },
          {
            prop: 'orderPayTime',
            label: '支付时间',
            minWidth: 155,
            align: 'center'
          },
          {
            prop: 'createBy',
            label: '创建人',
            minWidth: 100,
            align: 'center'
          },
          {
            prop: 'createTime',
            label: '创建时间',
            minWidth: 155,
            align: 'center'
          },
          {
            prop: 'remark',
            label: '备注',
            minWidth: 100,
            align: 'center'
          }
        ],
        list: []
      }
    }
  },
  async mounted () {},
  methods: {
    handleEvent (type, row) {
      switch (type) {
        case 'back':
          try {
            this.$emit('update:active', 1)
          } catch (error) {}
          break
        case 'pay':
          // const orderId = this.options.radioObject.orderId
          if (
            this.options.radioObject.orderStatus === 2 ||
            this.options.radioObject.orderStatus === 3
          ) {
            this.$message.error('该订单已支付，请勿重复支付！')
            return
          }
          let juep = this.options.radioObject?.orderType == 2 ? 4 : 2
          this.$emit('update:active', juep)
          this.$emit('resume', {
            orderNo: this.options.radioObject?.orderNo,
            money: this.options.radioObject?.orderMoney
          })
          // 获取详情
          // orderDetail({
          //   orderId
          // }).then(res => {
          //   this.$router.push({
          //     path: '/Creation/createStep',
          //     query: {
          //       orderNo: res.data.orderNo,
          //       money: res.data.orderMoney
          //     }
          //   })
          // })
          break
      }
    }
  }
}
</script>
<style lang="scss" scoped>
.wrapList {
  .text-left {
    margin-left: 4px;
  }
}
</style>
