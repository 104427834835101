var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "wrap", attrs: { id: "wrap" } },
    [
      _c(
        "div",
        { staticClass: "navSearch x-b", attrs: { id: "search-card" } },
        [
          _c("div", { staticClass: "left-search" }, [
            _c("div", [
              _c("div", { staticClass: "x-f" }, [
                _c(
                  "div",
                  { staticClass: "x-f marR10" },
                  [
                    _c("div", { staticClass: "fS14MR10" }, [
                      _vm._v("订单日期"),
                    ]),
                    _c("el-date-picker", {
                      staticClass: "marR10",
                      attrs: {
                        "unlink-panels": "",
                        size: "mini",
                        type: "datetimerange",
                        "start-placeholder": "开始日期",
                        "end-placeholder": "结束日期",
                        "default-time": ["00:00:00", "23:59:59"],
                        "value-format": "yyyy-MM-dd HH:mm:ss",
                      },
                      model: {
                        value: _vm.datetime,
                        callback: function ($$v) {
                          _vm.datetime = $$v
                        },
                        expression: "datetime",
                      },
                    }),
                    _c(
                      "el-radio-group",
                      {
                        staticClass: "marR10",
                        attrs: { size: "mini" },
                        model: {
                          value: _vm.filterTime,
                          callback: function ($$v) {
                            _vm.filterTime = $$v
                          },
                          expression: "filterTime",
                        },
                      },
                      [
                        _c("el-radio-button", { attrs: { label: "today" } }, [
                          _vm._v("今天"),
                        ]),
                        _c(
                          "el-radio-button",
                          { attrs: { label: "yesterday" } },
                          [_vm._v("昨天")]
                        ),
                        _c(
                          "el-radio-button",
                          { attrs: { label: "latelyThreeAndDay" } },
                          [_vm._v("近三天")]
                        ),
                      ],
                      1
                    ),
                    _c("selectAllFilter", {
                      staticClass: "marR20",
                      attrs: { filters: _vm.dropDownList },
                      model: {
                        value: _vm.queryParams,
                        callback: function ($$v) {
                          _vm.queryParams = $$v
                        },
                        expression: "queryParams",
                      },
                    }),
                    _c(
                      "el-button",
                      {
                        attrs: { type: "primary", size: "mini" },
                        on: { click: _vm.getList },
                      },
                      [_vm._v("查询")]
                    ),
                    _c(
                      "el-button",
                      {
                        attrs: { icon: "el-icon-download", size: "mini" },
                        on: { click: _vm.handleExport },
                      },
                      [_vm._v("导出 ")]
                    ),
                  ],
                  1
                ),
              ]),
              _c("div", { staticClass: "x-f marT10" }, [
                _c(
                  "div",
                  { staticClass: "x-f marR10" },
                  [
                    _c("div", { staticClass: "fS14MR10" }, [
                      _vm._v("取货日期"),
                    ]),
                    _c("el-date-picker", {
                      staticClass: "marR10",
                      attrs: {
                        "unlink-panels": "",
                        size: "mini",
                        type: "datetimerange",
                        "start-placeholder": "开始日期",
                        "end-placeholder": "结束日期",
                        "default-time": ["00:00:00", "23:59:59"],
                        "value-format": "yyyy-MM-dd HH:mm:ss",
                      },
                      model: {
                        value: _vm.pickUpDatetime,
                        callback: function ($$v) {
                          _vm.pickUpDatetime = $$v
                        },
                        expression: "pickUpDatetime",
                      },
                    }),
                    _c(
                      "el-radio-group",
                      {
                        attrs: { size: "mini" },
                        model: {
                          value: _vm.pickUpFilterTime,
                          callback: function ($$v) {
                            _vm.pickUpFilterTime = $$v
                          },
                          expression: "pickUpFilterTime",
                        },
                      },
                      [
                        _c("el-radio-button", { attrs: { label: "today" } }, [
                          _vm._v("今天"),
                        ]),
                        _c(
                          "el-radio-button",
                          { attrs: { label: "tomorrow" } },
                          [_vm._v("明天")]
                        ),
                        _c(
                          "el-radio-button",
                          { attrs: { label: "nextThreeAndDay" } },
                          [_vm._v("近三天")]
                        ),
                      ],
                      1
                    ),
                  ],
                  1
                ),
                _c(
                  "div",
                  { staticClass: "x-f marR10" },
                  [
                    _c("span", { staticClass: "fS14MR10" }, [_vm._v("类型")]),
                    _c("SelectLocal", {
                      staticStyle: { width: "180px" },
                      attrs: {
                        multiple: true,
                        option: {
                          data: _vm.dict.type.order_type,
                          value: "value",
                          label: "label",
                        },
                      },
                      model: {
                        value: _vm.queryParams.orderTypes,
                        callback: function ($$v) {
                          _vm.$set(_vm.queryParams, "orderTypes", $$v)
                        },
                        expression: "queryParams.orderTypes",
                      },
                    }),
                  ],
                  1
                ),
                _c(
                  "div",
                  { staticClass: "x-f marR10" },
                  [
                    _c("span", { staticClass: "fS14MR10" }, [
                      _vm._v("取货方式"),
                    ]),
                    _c("SelectLocal", {
                      staticStyle: { width: "180px" },
                      attrs: {
                        multiple: true,
                        option: {
                          data: _vm.dict.type.fetch_type,
                          value: "value",
                          label: "label",
                        },
                      },
                      model: {
                        value: _vm.queryParams.fetchTypes,
                        callback: function ($$v) {
                          _vm.$set(_vm.queryParams, "fetchTypes", $$v)
                        },
                        expression: "queryParams.fetchTypes",
                      },
                    }),
                  ],
                  1
                ),
              ]),
              _c("div", { staticClass: "x-f marT10" }, [
                _c(
                  "div",
                  { staticClass: "x-f marR10" },
                  [
                    _c("span", { staticClass: "fS14MR10" }, [
                      _vm._v("订单状态"),
                    ]),
                    _c("SelectLocal", {
                      staticStyle: { width: "180px" },
                      attrs: {
                        multiple: true,
                        option: {
                          data: _vm.orderStatusList,
                          value: "value",
                          label: "label",
                        },
                      },
                      model: {
                        value: _vm.queryParams.orderStatuss,
                        callback: function ($$v) {
                          _vm.$set(_vm.queryParams, "orderStatuss", $$v)
                        },
                        expression: "queryParams.orderStatuss",
                      },
                    }),
                  ],
                  1
                ),
                _c(
                  "div",
                  { staticClass: "x-f marR10" },
                  [
                    _c("span", { staticClass: "fS14MR10" }, [
                      _vm._v("生产部门"),
                    ]),
                    _c("SelectLocal", {
                      staticStyle: { width: "180px" },
                      attrs: {
                        multiple: true,
                        option: _vm.$select({
                          key: "listDepartment",
                        }).option,
                      },
                      model: {
                        value: _vm.queryParams.produceDeptIds,
                        callback: function ($$v) {
                          _vm.$set(_vm.queryParams, "produceDeptIds", $$v)
                        },
                        expression: "queryParams.produceDeptIds",
                      },
                    }),
                  ],
                  1
                ),
                _c(
                  "div",
                  { staticClass: "x-f marR10" },
                  [
                    _c("span", { staticClass: "fS14MR10" }, [
                      _vm._v("预定门店"),
                    ]),
                    _c("SelectRemote", {
                      staticStyle: { width: "180px" },
                      attrs: {
                        multiple: true,
                        option: _vm.$select({
                          key: "listShop",
                          option: {
                            option: {
                              buttons: [
                                {
                                  type: "more",
                                  option: {
                                    title: "选择门店",
                                    width: 1250,
                                    type: "TreeAndTable",
                                    formData: this.$dialog({ key: "shop" }),
                                  },
                                },
                              ],
                            },
                          },
                        }).option,
                      },
                      model: {
                        value: _vm.queryParams.orderShopIds,
                        callback: function ($$v) {
                          _vm.$set(_vm.queryParams, "orderShopIds", $$v)
                        },
                        expression: "queryParams.orderShopIds",
                      },
                    }),
                  ],
                  1
                ),
                _c(
                  "div",
                  { staticClass: "x-f" },
                  [
                    _c("span", { staticClass: "fS14MR10" }, [
                      _vm._v("取货门店"),
                    ]),
                    _c("SelectRemote", {
                      staticStyle: { width: "180px" },
                      attrs: {
                        multiple: true,
                        option: _vm.$select({
                          key: "listShop",
                          option: {
                            option: {
                              buttons: [
                                {
                                  type: "more",
                                  option: {
                                    title: "选择门店",
                                    width: 1250,
                                    type: "TreeAndTable",
                                    formData: this.$dialog({ key: "shop" }),
                                  },
                                },
                              ],
                            },
                          },
                        }).option,
                      },
                      model: {
                        value: _vm.queryParams.shopIds,
                        callback: function ($$v) {
                          _vm.$set(_vm.queryParams, "shopIds", $$v)
                        },
                        expression: "queryParams.shopIds",
                      },
                    }),
                  ],
                  1
                ),
              ]),
            ]),
          ]),
          _c("div", { staticClass: "right-control marR10" }, [
            _c(
              "div",
              { staticClass: "marB10" },
              [
                _c("span", { staticClass: "marR10" }, [_vm._v("自动通知生产")]),
                _c("el-switch", {
                  on: {
                    change: function ($event) {
                      return _vm.setSaveShopOrderTipConfig(
                        "produce",
                        _vm.shopOrderTip.shop_ORDER_CONFIG_TIP_74
                      )
                    },
                  },
                  model: {
                    value: _vm.shopOrderTip.shop_ORDER_CONFIG_TIP_74,
                    callback: function ($$v) {
                      _vm.$set(
                        _vm.shopOrderTip,
                        "shop_ORDER_CONFIG_TIP_74",
                        $$v
                      )
                    },
                    expression: "shopOrderTip.shop_ORDER_CONFIG_TIP_74",
                  },
                }),
              ],
              1
            ),
            _c(
              "div",
              { staticClass: "marB10" },
              [
                _c("span", { staticClass: "marR10" }, [_vm._v("自动接单")]),
                _c("el-switch", {
                  on: {
                    change: function ($event) {
                      return _vm.setSaveShopOrderTipConfig(
                        "orderTake",
                        _vm.shopOrderTip.shop_ORDER_CONFIG_TIP_71
                      )
                    },
                  },
                  model: {
                    value: _vm.shopOrderTip.shop_ORDER_CONFIG_TIP_71,
                    callback: function ($$v) {
                      _vm.$set(
                        _vm.shopOrderTip,
                        "shop_ORDER_CONFIG_TIP_71",
                        $$v
                      )
                    },
                    expression: "shopOrderTip.shop_ORDER_CONFIG_TIP_71",
                  },
                }),
              ],
              1
            ),
            _c(
              "div",
              { staticClass: "marB10" },
              [
                _c("span", { staticClass: "marR10" }, [
                  _vm._v("自动接单并打印"),
                ]),
                _c("el-switch", {
                  on: {
                    change: function ($event) {
                      return _vm.setSaveShopOrderTipConfig(
                        "orderTakePrint",
                        _vm.shopOrderTip.shop_ORDER_CONFIG_TIP_75
                      )
                    },
                  },
                  model: {
                    value: _vm.shopOrderTip.shop_ORDER_CONFIG_TIP_75,
                    callback: function ($$v) {
                      _vm.$set(
                        _vm.shopOrderTip,
                        "shop_ORDER_CONFIG_TIP_75",
                        $$v
                      )
                    },
                    expression: "shopOrderTip.shop_ORDER_CONFIG_TIP_75",
                  },
                }),
              ],
              1
            ),
          ]),
        ]
      ),
      _c("div", { staticClass: "content" }, [
        _c("div", { staticClass: "orderDetail" }, [
          _c("div", { staticClass: "orderReceipt" }, [
            _c("div", { staticClass: "title" }, [_vm._v("订单详情")]),
            _c(
              "div",
              { staticClass: "orderContent" },
              [
                _c(
                  "el-scrollbar",
                  { style: { height: _vm.tableHeight + "px" } },
                  [
                    _c("div", { staticClass: "orderInfo borderB" }, [
                      _c("div", { staticClass: "row" }, [
                        _c("div", { staticClass: "label" }, [
                          _vm._v(
                            " 订单编号:" +
                              _vm._s(this.reserveOrderInfo.billNo) +
                              " "
                          ),
                        ]),
                      ]),
                      _c("div", { staticClass: "row" }, [
                        _c("div", { staticClass: "label" }, [
                          _vm._v(
                            " 下单时间:" +
                              _vm._s(this.reserveOrderInfo.billDate) +
                              " "
                          ),
                        ]),
                      ]),
                      _c("div", { staticClass: "row" }, [
                        _c("div", { staticClass: "label" }, [
                          _vm._v(
                            " 取货门店:" +
                              _vm._s(this.reserveOrderInfo.shopName) +
                              " "
                          ),
                        ]),
                      ]),
                      _c("div", { staticClass: "row" }, [
                        _c("div", { staticClass: "label" }, [
                          _vm._v(
                            " 送货时间:" +
                              _vm._s(this.reserveOrderInfo.reachDate) +
                              " "
                          ),
                        ]),
                      ]),
                      _c("div", { staticClass: "row" }, [
                        _c("div", { staticClass: "label" }, [
                          _vm._v(
                            " 取货方式:" +
                              _vm._s(this.reserveOrderInfo.fetchTypeName) +
                              " "
                          ),
                        ]),
                        _c("div", { staticClass: "val" }),
                      ]),
                      _c("div", { staticClass: "row" }, [
                        _c("div", { staticClass: "label" }, [
                          _vm._v(
                            " 备注:" +
                              _vm._s(this.reserveOrderInfo.remark) +
                              " "
                          ),
                        ]),
                        _c("div", { staticClass: "val" }),
                      ]),
                    ]),
                    _c("div", { staticClass: "goodsInfo" }, [
                      _c("div", { staticClass: "goodsTitle-list" }, [
                        _c("div", { staticClass: "goodsTitle" }, [
                          _vm._v("编号"),
                        ]),
                        _c("div", { staticClass: "goodsTitle" }, [
                          _vm._v("名称"),
                        ]),
                        _c("div", { staticClass: "goodsTitle" }, [
                          _vm._v("数量"),
                        ]),
                        _c("div", { staticClass: "goodsTitle" }, [
                          _vm._v("单价"),
                        ]),
                        _c("div", { staticClass: "goodsTitle" }, [
                          _vm._v("小计"),
                        ]),
                      ]),
                      _c(
                        "div",
                        { staticClass: "goodsList" },
                        [
                          _vm._l(
                            _vm.reserveOrderInfo
                              .middleGroundBillOrderGoodsInfoResps,
                            function (item, index) {
                              return _c(
                                "div",
                                { key: index, staticClass: "goodsItem" },
                                [
                                  _c("div", [_vm._v(_vm._s(item.goodsNo))]),
                                  _c("div", { staticClass: "goodsItemName" }, [
                                    _vm._v(_vm._s(item.goodsName)),
                                  ]),
                                  _c("div", [_vm._v(_vm._s(item.unitQty))]),
                                  _c("div", [_vm._v(_vm._s(item.unitPrice))]),
                                  _c("div", [_vm._v(_vm._s(item.subtotal))]),
                                ]
                              )
                            }
                          ),
                          _c("div", { staticClass: "goodsTotal" }, [
                            _vm._v(
                              " 共" +
                                _vm._s(_vm.reserveOrderInfo.orderQty || 0) +
                                "件商品 "
                            ),
                          ]),
                        ],
                        2
                      ),
                    ]),
                    _c("div", { staticClass: "payInfo borderB" }, [
                      _c("div", { staticClass: "row" }, [
                        _c("div", { staticClass: "label width105" }, [
                          _vm._v("原价金额:"),
                        ]),
                        _c("div", { staticClass: "val" }, [
                          _vm._v(
                            " " +
                              _vm._s(this.reserveOrderInfo.billOldMoney) +
                              " "
                          ),
                        ]),
                      ]),
                      _c("div", { staticClass: "row" }, [
                        _c("div", { staticClass: "label width105" }, [
                          _vm._v("优惠金额:"),
                        ]),
                        _c("div", { staticClass: "val" }, [
                          _vm._v(
                            " " +
                              _vm._s(this.reserveOrderInfo.agioMoneySum) +
                              " "
                          ),
                        ]),
                      ]),
                      _c("div", { staticClass: "row" }, [
                        _c("div", { staticClass: "label width105" }, [
                          _vm._v("配送费用:"),
                        ]),
                        _c("div", { staticClass: "val" }, [
                          _vm._v(
                            " " +
                              _vm._s(this.reserveOrderInfo.deliveryFee) +
                              " "
                          ),
                        ]),
                      ]),
                      _c("div", { staticClass: "row" }, [
                        _c("div", { staticClass: "label width105" }, [
                          _vm._v("应实付金额:"),
                        ]),
                        _c("div", { staticClass: "val" }, [
                          _vm._v(_vm._s(_vm.reserveOrderInfo.billFactMoney)),
                        ]),
                      ]),
                      _c("div", { staticClass: "row" }, [
                        _c("div", { staticClass: "label width105" }, [
                          _vm._v("已收金额:"),
                        ]),
                        _c("div", { staticClass: "val" }, [
                          _vm._v(_vm._s(_vm.reserveOrderInfo.payMoney)),
                        ]),
                      ]),
                      _c("div", { staticClass: "row" }, [
                        _c("div", { staticClass: "label width105" }, [
                          _vm._v("未收金额:"),
                        ]),
                        _c("div", { staticClass: "val" }, [
                          _vm._v(_vm._s(_vm.reserveOrderInfo.notReceivedMoney)),
                        ]),
                      ]),
                    ]),
                    _c(
                      "div",
                      { staticClass: "payDetail borderB" },
                      [
                        _c("div", [_vm._v("付款明细")]),
                        _vm._l(
                          _vm.reserveOrderInfo
                            .billOrderListReservePayDetailsResps,
                          function (item, index) {
                            return _c(
                              "div",
                              { key: index, staticClass: "row" },
                              [
                                _c("div", { staticClass: "label" }, [
                                  _vm._v(_vm._s(item.payModeName)),
                                ]),
                                _vm._v(" : "),
                                _c("div", { staticClass: "val" }, [
                                  _vm._v(_vm._s(item.payMoney)),
                                ]),
                              ]
                            )
                          }
                        ),
                      ],
                      2
                    ),
                    _c("div", { staticClass: "clientInfo" }, [
                      _c("div", { staticClass: "row" }, [
                        _c("div", { staticClass: "label" }, [
                          _vm._v(
                            " 姓名:" +
                              _vm._s(this.reserveOrderInfo.receiverName) +
                              " "
                          ),
                        ]),
                      ]),
                      _c("div", { staticClass: "row" }, [
                        _c("div", { staticClass: "label" }, [
                          _vm._v(
                            " 电话:" +
                              _vm._s(this.reserveOrderInfo.receiverPhone) +
                              " "
                          ),
                        ]),
                      ]),
                      _c("div", { staticClass: "row" }, [
                        _c(
                          "div",
                          { staticStyle: { "text-align": "justify" } },
                          [
                            _vm._v(
                              " 地址:" +
                                _vm._s(this.reserveOrderInfo.receiverAddress) +
                                " "
                            ),
                          ]
                        ),
                      ]),
                    ]),
                  ]
                ),
              ],
              1
            ),
          ]),
        ]),
        _c(
          "div",
          { staticClass: "orderList" },
          [
            _c("div", { staticClass: "x-bc" }, [
              _c(
                "div",
                { staticClass: "x-f" },
                [
                  _c(
                    "el-button",
                    {
                      attrs: {
                        size: "mini",
                        type: "primary",
                        disabled:
                          this.takeDeliveryOrder.orderStatus != 4 ||
                          this.takeDeliveryOrder.orderInnerStatus != 0 ||
                          _vm.loadingTable ||
                          !this.takeDeliveryOrder.isFetchType,
                      },
                      on: {
                        click: function ($event) {
                          return _vm.getControls(1)
                        },
                      },
                    },
                    [_vm._v("接单")]
                  ),
                  _c(
                    "el-button",
                    {
                      attrs: {
                        size: "mini",
                        type: "primary",
                        disabled:
                          this.takeDeliveryOrder.orderStatus != 4 ||
                          this.takeDeliveryOrder.orderInnerStatus != 0 ||
                          _vm.loadingTable ||
                          !this.takeDeliveryOrder.isFetchType,
                      },
                      on: {
                        click: function ($event) {
                          return _vm.getControls(10)
                        },
                      },
                    },
                    [_vm._v("拒接")]
                  ),
                  _c(
                    "el-button",
                    {
                      attrs: {
                        size: "mini",
                        type: "primary",
                        disabled:
                          !_vm.takeDeliveryOrder.billId ||
                          _vm.loadingTable ||
                          !this.takeDeliveryOrder.isFetchType,
                      },
                      on: {
                        click: function ($event) {
                          return _vm.getControls(2)
                        },
                      },
                    },
                    [_vm._v("订单打印")]
                  ),
                  _c(
                    "el-button",
                    {
                      attrs: {
                        size: "mini",
                        type: "primary",
                        disabled:
                          !(
                            this.takeDeliveryOrder.orderStatus == 4 &&
                            [2, 4].includes(
                              this.takeDeliveryOrder.orderInnerStatus
                            ) &&
                            !_vm.loadingTable
                          ) ||
                          _vm.disabled == "通知生产" ||
                          !this.takeDeliveryOrder.isFetchType,
                      },
                      on: {
                        click: function ($event) {
                          return _vm.getControls(3)
                        },
                      },
                    },
                    [_vm._v("通知生产")]
                  ),
                  this.takeDeliveryOrder &&
                  this.takeDeliveryOrder.shopNo == "0000"
                    ? _c(
                        "el-button",
                        {
                          attrs: {
                            size: "mini",
                            type: "primary",
                            disabled:
                              this.takeDeliveryOrder.orderStatus != 4 ||
                              !(2 <= this.takeDeliveryOrder.orderInnerStatus) ||
                              !(this.takeDeliveryOrder.orderInnerStatus < 15) ||
                              _vm.loadingTable ||
                              !_vm.fullAmount ||
                              !this.takeDeliveryOrder.isFetchType,
                          },
                          on: {
                            click: function ($event) {
                              return _vm.getControls(4)
                            },
                          },
                        },
                        [_vm._v("发货")]
                      )
                    : _c(
                        "el-button",
                        {
                          attrs: {
                            size: "mini",
                            type: "primary",
                            disabled:
                              this.takeDeliveryOrder.orderStatus != 4 ||
                              !(2 <= this.takeDeliveryOrder.orderInnerStatus) ||
                              !(this.takeDeliveryOrder.orderInnerStatus < 11) ||
                              _vm.loadingTable ||
                              !_vm.fullAmount ||
                              this.takeDeliveryOrder.fetchType == 1 ||
                              !this.takeDeliveryOrder.isFetchType,
                          },
                          on: {
                            click: function ($event) {
                              return _vm.getControls(4)
                            },
                          },
                        },
                        [_vm._v("发货")]
                      ),
                  _c(
                    "el-button",
                    {
                      attrs: {
                        size: "mini",
                        type: "primary",
                        disabled: !(
                          this.takeDeliveryOrder.orderStatus == 4 &&
                          this.takeDeliveryOrder.orderInnerStatus >= 2 &&
                          this.takeDeliveryOrder.fetchType == 2 &&
                          !_vm.loadingTable &&
                          this.takeDeliveryOrder.isFetchType
                        ),
                      },
                      on: {
                        click: function ($event) {
                          return _vm.getControls(5)
                        },
                      },
                    },
                    [_vm._v("打印配送单")]
                  ),
                  _c(
                    "el-dropdown",
                    {
                      staticClass: "marL10 marR10",
                      attrs: {
                        trigger: "click",
                        "split-button": "",
                        type: "primary",
                        size: "mini",
                        disabled:
                          ((this.takeDeliveryOrder.orderStatus != 4 &&
                            this.takeDeliveryOrder.orderStatus != 5) ||
                            this.takeDeliveryOrder.orderInnerStatus < 2 ||
                            (this.takeDeliveryOrder.deliveryStatus != -1 &&
                              this.takeDeliveryOrder.deliveryStatus != 0 &&
                              this.takeDeliveryOrder.deliveryStatus != 100) ||
                            (this.takeDeliveryOrder.fetchType != 2 &&
                              this.takeDeliveryOrder.fetchType != 3)) &&
                          ((this.takeDeliveryOrder.orderStatus != 4 &&
                            this.takeDeliveryOrder.orderStatus != 5) ||
                            this.takeDeliveryOrder.orderInnerStatus < 2 ||
                            (this.takeDeliveryOrder.deliveryStatus != 1 &&
                              this.takeDeliveryOrder.deliveryStatus != 2) ||
                            (this.takeDeliveryOrder.fetchType != 2 &&
                              this.takeDeliveryOrder.fetchType != 3)),
                      },
                      on: {
                        click: function ($event) {
                          return _vm.getControls(6)
                        },
                        command: function ($event) {
                          return _vm.getControls(15)
                        },
                      },
                    },
                    [
                      _vm._v(" 呼叫配送 "),
                      _c(
                        "el-dropdown-menu",
                        { attrs: { slot: "dropdown" }, slot: "dropdown" },
                        [
                          _c(
                            "el-dropdown-item",
                            { attrs: { command: "取消配送" } },
                            [_vm._v("取消配送")]
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                  _c(
                    "el-button",
                    {
                      attrs: {
                        size: "mini",
                        type: "primary",
                        disabled:
                          (this.takeDeliveryOrder.refundStatus != 1 ||
                            _vm.loadingTable ||
                            !this.takeDeliveryOrder.isFetchType) &&
                          (!(
                            this.takeDeliveryOrder.orderStatus == 4 &&
                            [0, 2, 3, 4].includes(
                              this.takeDeliveryOrder.orderInnerStatus
                            ) &&
                            this.takeDeliveryOrder.orderType == "5"
                          ) ||
                            _vm.loadingTable ||
                            !this.takeDeliveryOrder.isFetchType),
                      },
                      on: {
                        click: function ($event) {
                          return _vm.getControls(8)
                        },
                      },
                    },
                    [_vm._v("确认退款")]
                  ),
                  _c(
                    "el-button",
                    {
                      attrs: {
                        size: "mini",
                        type: "primary",
                        disabled:
                          this.takeDeliveryOrder.refundStatus != 1 ||
                          _vm.loadingTable ||
                          !this.takeDeliveryOrder.isFetchType,
                      },
                      on: {
                        click: function ($event) {
                          return _vm.getControls(9)
                        },
                      },
                    },
                    [_vm._v("拒接退款")]
                  ),
                  _c(
                    "el-button",
                    {
                      attrs: {
                        size: "mini",
                        type: "primary",
                        disabled:
                          !(
                            this.takeDeliveryOrder.orderStatus == 4 &&
                            [0, 2, 3, 4].includes(
                              this.takeDeliveryOrder.orderInnerStatus
                            )
                          ) ||
                          _vm.loadingTable ||
                          !this.takeDeliveryOrder.isFetchType,
                      },
                      on: {
                        click: function ($event) {
                          return _vm.getControls(11)
                        },
                      },
                    },
                    [_vm._v("修改预订单")]
                  ),
                ],
                1
              ),
              _c(
                "div",
                [
                  _c("span", { staticClass: "marR10" }, [_vm._v("发货仓库")]),
                  _c("SelectRemote", {
                    staticStyle: { width: "200px" },
                    attrs: {
                      option: _vm.$select({
                        key: "listStore",
                        option: {
                          option: {
                            clearable: true,
                          },
                        },
                      }).option,
                    },
                    on: { selectChange: _vm.changeStoreId },
                    model: {
                      value: _vm.outStoreId,
                      callback: function ($$v) {
                        _vm.outStoreId = $$v
                      },
                      expression: "outStoreId",
                    },
                  }),
                ],
                1
              ),
            ]),
            _c(
              "el-table",
              {
                directives: [
                  {
                    name: "loading",
                    rawName: "v-loading",
                    value: _vm.loadingTable,
                    expression: "loadingTable",
                  },
                ],
                ref: "multipleTable",
                staticClass: "marT10",
                attrs: {
                  data: _vm.tableData,
                  border: "",
                  "max-height": _vm.tableHeight,
                  height: _vm.tableHeight,
                  "show-summary": "",
                  "summary-method": _vm.getSummaries,
                  "element-loading-text": "操作中",
                },
                on: { "row-click": _vm.handleRowClick },
              },
              [
                _c("el-table-column", {
                  attrs: { width: "55", align: "center" },
                  scopedSlots: _vm._u([
                    {
                      key: "default",
                      fn: function (scope) {
                        return [
                          _c(
                            "el-radio",
                            {
                              attrs: { label: scope.row },
                              model: {
                                value: _vm.takeDeliveryOrder,
                                callback: function ($$v) {
                                  _vm.takeDeliveryOrder = $$v
                                },
                                expression: "takeDeliveryOrder",
                              },
                            },
                            [_vm._v(_vm._s(""))]
                          ),
                        ]
                      },
                    },
                  ]),
                }),
                _c("el-table-column", {
                  attrs: {
                    type: "index",
                    label: "序号",
                    width: "50",
                    align: "center",
                  },
                }),
                _vm._l(_vm.tableFields, function (item) {
                  return _c("el-table-column", {
                    key: item.title,
                    attrs: {
                      "show-overflow-tooltip": "",
                      prop: item.prop,
                      label: item.title,
                      width: item.width,
                      align: "center",
                    },
                  })
                }),
              ],
              2
            ),
            _c("bottomPage", {
              attrs: {
                getList: _vm.getList,
                isSelectAll: false,
                totalCom: _vm.total,
              },
              model: {
                value: _vm.queryParams,
                callback: function ($$v) {
                  _vm.queryParams = $$v
                },
                expression: "queryParams",
              },
            }),
          ],
          1
        ),
      ]),
      _c("orderPrint", {
        ref: "print",
        attrs: { orderInfo: _vm.orderInfo, shopId: _vm.shopId },
      }),
      _c("c-dialog", {
        attrs: {
          title: "选择打印机",
          width: 400,
          showDialog: _vm.dialogFormVisible,
        },
        on: {
          "update:showDialog": function ($event) {
            _vm.dialogFormVisible = $event
          },
          "update:show-dialog": function ($event) {
            _vm.dialogFormVisible = $event
          },
        },
        scopedSlots: _vm._u([
          {
            key: "content",
            fn: function () {
              return [
                _c(
                  "el-form",
                  {
                    ref: "printForm",
                    attrs: {
                      model: _vm.printForm,
                      rules: _vm.printRules,
                      "label-width": "90px",
                    },
                  },
                  [
                    _c(
                      "el-form-item",
                      { attrs: { prop: "printerName", label: "打印机" } },
                      [
                        _c(
                          "div",
                          { staticClass: "x-f" },
                          [
                            _c(
                              "el-select",
                              {
                                staticClass: "marR10",
                                attrs: {
                                  size: "mini",
                                  placeholder: "请选择",
                                  clearable: "",
                                  filterable: "",
                                },
                                model: {
                                  value: _vm.printForm.printerName,
                                  callback: function ($$v) {
                                    _vm.$set(_vm.printForm, "printerName", $$v)
                                  },
                                  expression: "printForm.printerName",
                                },
                              },
                              _vm._l(_vm.printerList, function (item) {
                                return _c("el-option", {
                                  key: item.value,
                                  attrs: { label: item.name, value: item.name },
                                })
                              }),
                              1
                            ),
                            _c(
                              "el-button",
                              {
                                attrs: {
                                  size: "mini",
                                  icon: "el-icon-refresh-right",
                                },
                                on: {
                                  click: function ($event) {
                                    return _vm.getAcquirePrint("bt")
                                  },
                                },
                              },
                              [_vm._v("刷新")]
                            ),
                          ],
                          1
                        ),
                      ]
                    ),
                  ],
                  1
                ),
              ]
            },
            proxy: true,
          },
          {
            key: "buttonList",
            fn: function () {
              return [
                _c("el-button", { on: { click: _vm.cancel } }, [
                  _vm._v("取 消"),
                ]),
                _c(
                  "el-button",
                  {
                    attrs: { type: "primary" },
                    on: { click: _vm.handlePrint },
                  },
                  [_vm._v("确 定")]
                ),
              ]
            },
            proxy: true,
          },
        ]),
      }),
      _c("reserveDialog", {
        attrs: {
          openDialog: _vm.openReserveDialog,
          orderData: _vm.editReserveOrderInfo.billOrderReq,
        },
        on: {
          "update:openDialog": function ($event) {
            _vm.openReserveDialog = $event
          },
          "update:open-dialog": function ($event) {
            _vm.openReserveDialog = $event
          },
          billOrderReqCom: _vm.billOrderReqCom,
        },
      }),
      _c("CallDelivery", {
        attrs: {
          openDialog: _vm.openCallDeliveryDialog,
          billId: _vm.takeDeliveryOrder.billId,
          shopId: _vm.shopId,
          fetchType: _vm.takeDeliveryOrder.fetchType,
          reserveOrderInfo: _vm.reserveOrderInfo,
        },
        on: {
          "update:openDialog": function ($event) {
            _vm.openCallDeliveryDialog = $event
          },
          "update:open-dialog": function ($event) {
            _vm.openCallDeliveryDialog = $event
          },
          getControls: _vm.getControls,
        },
      }),
      _c("CancDelivery", {
        attrs: {
          openDialog: _vm.openCancelDialog,
          billId: _vm.takeDeliveryOrder.billId,
        },
        on: {
          "update:openDialog": function ($event) {
            _vm.openCancelDialog = $event
          },
          "update:open-dialog": function ($event) {
            _vm.openCancelDialog = $event
          },
          getControls: _vm.getControls,
        },
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }