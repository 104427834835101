<template>
  <div class="wrap" id="wrap">
    <el-form ref="form" :model="orderAutoPrintSetForm" :rules="rules">
      <cardTitleCom cardTitle="订单自动厨打设置">
        <template #rightCardTitle>
          <el-button class="marR10" type="primary" size="mini" @click="getAcquirePrint('bt')">刷新打印机信息</el-button>
          <el-button class="marR10" type="primary" size="mini" @click="download">下载打印插件</el-button>
        </template>
        <template slot="cardContent">
          <div class="tableBox">
            <div class="tableContent">
              <!-- 按钮 -->
              <div class="tableBtn x-bc">
                <div>
                  <!-- <el-button
                  type="danger"
                  icon="el-icon-delete"
                  :disabled="multiple"
                  size="mini"
                  @click="getDelete"
                  >删除
                </el-button> -->
                </div>
                <div>
                  <el-button type="primary" size="mini" @click="submitForm" icon="el-icon-plus">保存
                  </el-button>

                  <el-button icon="el-icon-refresh" size="mini" @click="test($event)">刷新
                  </el-button>
                </div>
              </div>
              <el-table ref="multipleTable" v-loading="loadingTable" tooltip-effect="dark"
                :data="orderAutoPrintSetForm.tableDataAvailable" border :max-height="tableHeight" :height="tableHeight"
                @cell-mouse-enter="cellMouseEnter" @cell-mouse-leave="cellMouseLeave">
                <!-- <el-table-column type="selection" align="center" width="55" /> -->
                <el-table-column align="center" :label="tableCellLabel" width="80">
                  <template v-slot="scope">
                    <i v-if="scope.row.hoverRow" @click="rowAvailable('push', scope.$index)"
                      class="el-icon-circle-plus operatePush"></i>
                    <i v-if="scope.row.hoverRow" @click="
                        rowAvailable('del', scope.$index, scope.row.printerId)
                      " class="el-icon-remove operateDel"></i>
                    <div v-if="!scope.row.hoverRow">{{ scope.$index + 1 }}</div>
                  </template>
                </el-table-column>
                <el-table-column label="打印机编号" align="center" prop="printerNo">
                  <template slot-scope="scope">
                    <el-form-item :prop="
                        'tableDataAvailable.' + scope.$index + '.printerNo'
                      " :rules="rules[`tableDataAvailable.printerNo`]">
                      <el-input v-model="scope.row.printerNo" />
                    </el-form-item>
                  </template>
                </el-table-column>

                <el-table-column show-overflow-tooltip label="打印机名称" align="center" prop="printerName">
                  <template slot-scope="scope">
                    <el-form-item :prop="
                        'tableDataAvailable.' + scope.$index + '.printerName'
                      " :rules="rules[`tableDataAvailable.printerName`]">
                      <el-select size="mini" v-model="scope.row.printerName" placeholder="请选择" clearable filterable>
                        <el-option v-for="item in printerList" :key="item.value" :label="item.name" :value="item.name">
                        </el-option>
                      </el-select>
                    </el-form-item>
                  </template>
                </el-table-column>
                <el-table-column show-overflow-tooltip label="打印机份数" align="center" prop="printCount">
                  <template slot-scope="scope">
                    <el-form-item :prop="
                        'tableDataAvailable.' + scope.$index + '.printCount'
                      " :rules="rules[`tableDataAvailable.printCount`]">
                      <el-input v-model="scope.row.printCount" type="number" />
                    </el-form-item>
                  </template>
                </el-table-column>
                <el-table-column show-overflow-tooltip label="适用打印方案" align="center" prop="printCaseId">
                  <template slot-scope="scope">
                    <el-form-item :prop="
                        'tableDataAvailable.' + scope.$index + '.printCaseId'
                      " :rules="rules[`tableDataAvailable.printCaseId`]">
                      <el-select v-model="scope.row.printCaseId" placeholder="请选择" filterable clearable>
                        <el-option v-for="item in listCookPrint" :key="item.printCaseId" :label="item.printCaseName"
                          :value="item.printCaseId">
                        </el-option>
                      </el-select>
                    </el-form-item>
                  </template>
                </el-table-column>
              </el-table>
              <pagination v-show="total > 0" :total="total" :page.sync="queryParams.pageNum"
                :limit.sync="queryParams.pageSize" @pagination="getList" />
            </div>
          </div>
        </template>
      </cardTitleCom>
    </el-form>
  </div>
</template>

<script>
// 局部引入
import { hiprint } from 'vue-plugin-hiprint'
import {
  orderListAPI, //门店订单中台列表
  delOrderInfo, //删除门店订单中台状态
  saveOrUpdateAPI //新增+修改
} from '@/api/shop/orderCenter/orderAutoPrintSet' //门店中台设置
import { cookPrintListAPI } from '@/api/shop/base/cookPrintCase' //厨打商品方案
import cardTitleCom from '@/views/components/cardTitleCom' //卡片标题
export default {
  name: 'orderAutoPrintSet',
  components: { cardTitleCom },
  data() {
    return {
      printerIds: [], //删除
      printerList: [], //打印机
      tableCellLabel: '序号',
      orderAutoPrintSetForm: {
        tableDataAvailable: [] // 可用商品表格数据
      },
      rules: {
        'tableDataAvailable.printerNo': [
          {
            required: true,
            message: '打印机编号不能为空',
            trigger: ['blur', 'change']
          }
        ],
        'tableDataAvailable.printerName': [
          {
            required: true,
            message: '打印机名称不能为空',
            trigger: ['blur', 'change']
          }
        ],
        'tableDataAvailable.printCount': [
          {
            required: true,
            message: '打印机份数不能为空',
            trigger: ['blur', 'change']
          }
        ],
        'tableDataAvailable.printCaseId': [
          {
            required: true,
            message: '打印方案不能为空',
            trigger: ['blur', 'change']
          }
        ]
      },
      loading: false,
      loadingTable: false,
      multiple: false, // 非单个禁用
      ids: [], // 选中数组
      total: 0, // 总条数
      // 查询
      queryParams: {
        pageNum: 1,
        pageSize: 15
      },
      listCookPrint: [], //厨打
      vivwH: 0, //页面高度
      searchH: 0, //搜索框高度
      paginationH: 40
    }
  },
  async created() {
    this.loading = true
    const res = await cookPrintListAPI({
      pageNum: 1,
      pageSize: 999,
      isStop: false //是否停用
    })
    this.listCookPrint = res.rows
    //打印机设置
    await this.getList()
    this.loading = false
  },
  computed: {
    tableHeight() {
      return this.vivwH - this.paginationH - 100
    }
  },
  async mounted() {
    this.$nextTick(() => {
      this.vivwH = document.getElementById('wrap')?.clientHeight
    })
    window.addEventListener('resize', this.handleResize)
    setTimeout(() => {
      this.getAcquirePrint()
    }, 500)
  },
  beforeDestroy() {
    window.removeEventListener('resize', this.handleResize)
  },
  methods: {
    async getAcquirePrint(val) {
      this.printerList = []
      const hiprintTemplate2 = new hiprint.PrintTemplate()
      const counterList = hiprintTemplate2.getPrinterList()
      if (counterList) {
        this.printerList = counterList.map((item, index) => {
          return {
            name: item.name,
            value: index
          }
        })
      }
      if (val == 'bt') {
        this.$message.success('刷新成功')
      }
    },
    download() {
      window.open(
        'https://balingsoft-oss.oss-cn-shenzhen.aliyuncs.com/file/2023-12-20/hiprint_win_x32-1.0.9.exe'
      )
    },
    handleResize() {
      this.vivwH = document.getElementById('wrap')?.clientHeight
    },
    //表格行hover时,显示操作加减号
    cellMouseEnter(row) {
      let eleIndex = this.orderAutoPrintSetForm.tableDataAvailable.indexOf(row)
      for (
        let index = 0;
        index < this.orderAutoPrintSetForm.tableDataAvailable.length;
        index++
      ) {
        const element = this.orderAutoPrintSetForm.tableDataAvailable[index]
        if (eleIndex == index) {
          element['hoverRow'] = true
        } else {
          element['hoverRow'] = false
        }
      }
      this.tableCellLabel = '操作'
    },
    //表格行离开hover时,不显示操作加减号,显示序号
    cellMouseLeave() {
      // 移除hover的事件
      for (
        let index = 0;
        index < this.orderAutoPrintSetForm.tableDataAvailable.length;
        index++
      ) {
        const element = this.orderAutoPrintSetForm.tableDataAvailable[index]
        element['hoverRow'] = false
      }
      this.tableCellLabel = '序号'
    },
    /** 查询表格列表 */
    async getList() {
      if (!this.loadingTable) {
        this.loadingTable = true
      }
      const { rows, total } = await orderListAPI(this.queryParams)
      // rows.forEach((item) => {
      //   this.$set(item, "printCaseId", 666);
      // });
      this.orderAutoPrintSetForm.tableDataAvailable = rows
      this.total = total
      if (this.orderAutoPrintSetForm.tableDataAvailable.length <= 0) {
        this.orderAutoPrintSetForm.tableDataAvailable = [{}]
      }

      this.loadingTable = false
    },
    /** 保存按钮 */
    async submitForm() {
      this.$refs['form'].validate(async valid => {
        if (valid) {
          try {
            this.loadingTable = true
            if (this.printerIds?.length > 0) {
              await delOrderInfo(this.printerIds)
            }
            await saveOrUpdateAPI(this.orderAutoPrintSetForm.tableDataAvailable)
            this.$message({
              message: '保存成功',
              type: 'success'
            })
            await this.getList()
            if (this.orderAutoPrintSetForm.tableDataAvailable.length <= 0) {
              this.orderAutoPrintSetForm.tableDataAvailable = [{}]
            }
          } catch (error) {
            this.loadingTable = false
          }
        }
      })
    },

    // 适用商品表格当中增加/减少一行方法
    rowAvailable(name, index, printerId) {
      if (name === 'push') {
        this.orderAutoPrintSetForm.tableDataAvailable.splice(index + 1, 0, {})
      } else {
        if (this.orderAutoPrintSetForm.tableDataAvailable.length <= 1) {
          this.orderAutoPrintSetForm.tableDataAvailable = [{}]
        } else {
          if (printerId) {
            this.printerIds.push(printerId)
          }
          this.orderAutoPrintSetForm.tableDataAvailable.splice(index, 1)
        }
      }
    },

    // 删除
    async getDelete() {
      this.$confirm('确认删除所选打印机, 是否继续?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      })
        .then(async () => {
          if (this.ids.length > 0) {
            // 删除api
            await delOrderInfo(this.ids),
              this.$message({
                type: 'success',
                message: '删除成功'
              })
          } else {
          }
          // await this.getList()
        })
        .catch(() => { })
    },
    // 多选框选中数据
    handleSelectionChange(selection) {
      // 筛选单据id 数据库
      this.ids = selection
        .map(item => {
          // 如果需要筛选其他属性，可以在这里进行修改
          return item.printerId
        })
        .filter(item => typeof item !== 'undefined') // 单据id

      // 单据id
      this.multiple = !selection.length
    },

    // 点击行选中
    handleRowClick(row) {
      // 获取表格对象
      // 切换行选中状态
      this.$refs.multipleTable.toggleRowSelection(row)
    },
    // 刷新按钮
    test(e) {
      let target = e.target
      if (target.nodeName == 'I' || target.nodeName == 'SPAN') {
        target = e.target.parentNode
      }
      target.blur()
      this.getList()
    },
    // 点击全选所有数据
    async selectAll(value) {
      if (value) {
        this.loadingTable = true
        const res = await orderListAPI({
          ...this.queryParams,
          pageNum: 1,
          pageSize: this.total
        })
        // 重新渲染表格
        this.tableData = res.rows
        // 表格多选
        this.tableData.map(row => {
          this.$nextTick(() => {
            this.$refs.multipleTable.toggleRowSelection(row, value)
          })
        })
        this.loadingTable = false
      } else {
        // 取消全选
        this.$nextTick(() => {
          this.$refs.multipleTable.clearSelection()
        })
        this.getList()
      }
    }
    //  resetForm() {
    //   this.form = {
    //     printerNo: "",
    //     printerName: "",
    //     printCount: "",
    //     printCaseName: "",
    //   };
    // },
  }
}
</script>

<style lang="scss" scoped>
.wrap {
  padding: 10px 10px 0 10px;
  background-color: #eaeaea;
  height: calc(100vh - 84px);
  .tableContent {
    margin: 6px 10px 10px 10px;
    .tableBtn {
      margin: 6px 0;
    }
  }
  ::v-deep .el-form-item__content {
    margin-left: 0 !important;
  }
  ::v-deep .el-form-item__error {
    position: static;
  }
}
::v-deep .el-form-item {
  margin: 0;
  padding: 0;
}
::v-deep .pagination-container {
  height: 40px;
}
//输入框原生属性 /** * 解决el-input设置类型为number时，中文输入法光标上移问题
::v-deep .el-input__inner {
  line-height: 1px !important;
}

/** *
解决el-input设置类型为number时，去掉输入框后面上下箭头 **/
::v-deep input[type="number"]::-webkit-inner-spin-button,
input[type="number"]::-webkit-outer-spin-button {
  -webkit-appearance: none !important;
  margin: 0 !important;
}
</style>
