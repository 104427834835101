<template>
  <!-- 呼叫配送弹窗 -->
  <div class="callDelivery">
    <el-dialog title="取消配送" :visible.sync="openDialog" width="500px" :modal="true" :close-on-click-modal="false"
      :before-close="handleClose">
      <div class="content">
        <el-form label-width="100px" :model="form" :rules="rules" ref="form">
          <el-form-item label="取消原因" prop="cancelReason">
            <el-input v-model="form.cancelReason" size="mini" style="width:300px" />
          </el-form-item>
        </el-form>
      </div>
      <div class="footer-btn">
        <el-button type="primary" @click="handleClose(1)" class="ml10" :disabled='disabled'>确定</el-button>
        <el-button class="cancelBtn" @click="handleClose(2)">取消</el-button>
      </div>
    </el-dialog>
  </div>
</template>

<script>
import {
getCancelDelivery
} from '@/api/shop/orderCenter/orderManage' //门店中台
export default {
  name: "CancDelivery",
  props: {
    // 弹窗开关
    openDialog: {
      type: Boolean,
      default: false,
    },
    billId: {
      type: String,
      default: '',
    },
    shopId: {
      type: String,
      default: '',
    }
  },
  data() {
    return {
      disabled: false,
      list: [],
      form: {
        cancelReason: ''
      },
      //校验规则
      rules: {
        cancelReason: [
          {
            required: true,
            message: "请输入取消原因",
            trigger: ["blur", "change"],
          },
        ]
      }
    };
  },
  watch: {
    openDialog(val) {
      if (val) {
        this.form.billId = this.billId
      } else {
        this.reset()
      }
    }
  },
  methods: {
    async handleClose(value) {
      if (value == 1) {
        if (this.disabled) {
          return
        }
        this.disabled = true
        this.$refs["form"].validate(async (valid) => {
          if (valid) {
            try {
              await getCancelDelivery(this.form)
              this.$emit("update:openDialog", false);
              this.$emit("getControls", 16);
              this.disabled = false
            } catch (error) {
              // this.$message.error(error);
              this.disabled = false
            }
          }
        });
      } else {
        this.$emit("update:openDialog", false);
      }
    },
    reset() {
      this.form = {
        cancelReason: ''
      };
      this.$refs.form.resetFields();
    }
  }
};
</script>

<style lang="scss" scoped>
.callDelivery {
  ::v-deep .el-form-item {
    margin-bottom: 10px;
  }
  ::v-deep .el-dialog__body {
    padding: 0;
  }

  //底部按钮
  .footer-btn {
    // height: 60px;
    width: 100%;
    display: flex;
    flex-direction: row-reverse;
    border-top: 1px solid #e3e9ee;
    padding: 1% 3%;
  }
}
</style>
