var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "wrap", attrs: { id: "wrap" } },
    [
      _c(
        "el-form",
        {
          ref: "form",
          attrs: { model: _vm.orderAutoPrintSetForm, rules: _vm.rules },
        },
        [
          _c(
            "cardTitleCom",
            {
              attrs: { cardTitle: "订单自动厨打设置" },
              scopedSlots: _vm._u([
                {
                  key: "rightCardTitle",
                  fn: function () {
                    return [
                      _c(
                        "el-button",
                        {
                          staticClass: "marR10",
                          attrs: { type: "primary", size: "mini" },
                          on: {
                            click: function ($event) {
                              return _vm.getAcquirePrint("bt")
                            },
                          },
                        },
                        [_vm._v("刷新打印机信息")]
                      ),
                      _c(
                        "el-button",
                        {
                          staticClass: "marR10",
                          attrs: { type: "primary", size: "mini" },
                          on: { click: _vm.download },
                        },
                        [_vm._v("下载打印插件")]
                      ),
                    ]
                  },
                  proxy: true,
                },
              ]),
            },
            [
              _c("template", { slot: "cardContent" }, [
                _c("div", { staticClass: "tableBox" }, [
                  _c(
                    "div",
                    { staticClass: "tableContent" },
                    [
                      _c("div", { staticClass: "tableBtn x-bc" }, [
                        _c("div"),
                        _c(
                          "div",
                          [
                            _c(
                              "el-button",
                              {
                                attrs: {
                                  type: "primary",
                                  size: "mini",
                                  icon: "el-icon-plus",
                                },
                                on: { click: _vm.submitForm },
                              },
                              [_vm._v("保存 ")]
                            ),
                            _c(
                              "el-button",
                              {
                                attrs: {
                                  icon: "el-icon-refresh",
                                  size: "mini",
                                },
                                on: {
                                  click: function ($event) {
                                    return _vm.test($event)
                                  },
                                },
                              },
                              [_vm._v("刷新 ")]
                            ),
                          ],
                          1
                        ),
                      ]),
                      _c(
                        "el-table",
                        {
                          directives: [
                            {
                              name: "loading",
                              rawName: "v-loading",
                              value: _vm.loadingTable,
                              expression: "loadingTable",
                            },
                          ],
                          ref: "multipleTable",
                          attrs: {
                            "tooltip-effect": "dark",
                            data: _vm.orderAutoPrintSetForm.tableDataAvailable,
                            border: "",
                            "max-height": _vm.tableHeight,
                            height: _vm.tableHeight,
                          },
                          on: {
                            "cell-mouse-enter": _vm.cellMouseEnter,
                            "cell-mouse-leave": _vm.cellMouseLeave,
                          },
                        },
                        [
                          _c("el-table-column", {
                            attrs: {
                              align: "center",
                              label: _vm.tableCellLabel,
                              width: "80",
                            },
                            scopedSlots: _vm._u([
                              {
                                key: "default",
                                fn: function (scope) {
                                  return [
                                    scope.row.hoverRow
                                      ? _c("i", {
                                          staticClass:
                                            "el-icon-circle-plus operatePush",
                                          on: {
                                            click: function ($event) {
                                              return _vm.rowAvailable(
                                                "push",
                                                scope.$index
                                              )
                                            },
                                          },
                                        })
                                      : _vm._e(),
                                    scope.row.hoverRow
                                      ? _c("i", {
                                          staticClass:
                                            "el-icon-remove operateDel",
                                          on: {
                                            click: function ($event) {
                                              return _vm.rowAvailable(
                                                "del",
                                                scope.$index,
                                                scope.row.printerId
                                              )
                                            },
                                          },
                                        })
                                      : _vm._e(),
                                    !scope.row.hoverRow
                                      ? _c("div", [
                                          _vm._v(_vm._s(scope.$index + 1)),
                                        ])
                                      : _vm._e(),
                                  ]
                                },
                              },
                            ]),
                          }),
                          _c("el-table-column", {
                            attrs: {
                              label: "打印机编号",
                              align: "center",
                              prop: "printerNo",
                            },
                            scopedSlots: _vm._u([
                              {
                                key: "default",
                                fn: function (scope) {
                                  return [
                                    _c(
                                      "el-form-item",
                                      {
                                        attrs: {
                                          prop:
                                            "tableDataAvailable." +
                                            scope.$index +
                                            ".printerNo",
                                          rules:
                                            _vm.rules[
                                              "tableDataAvailable.printerNo"
                                            ],
                                        },
                                      },
                                      [
                                        _c("el-input", {
                                          model: {
                                            value: scope.row.printerNo,
                                            callback: function ($$v) {
                                              _vm.$set(
                                                scope.row,
                                                "printerNo",
                                                $$v
                                              )
                                            },
                                            expression: "scope.row.printerNo",
                                          },
                                        }),
                                      ],
                                      1
                                    ),
                                  ]
                                },
                              },
                            ]),
                          }),
                          _c("el-table-column", {
                            attrs: {
                              "show-overflow-tooltip": "",
                              label: "打印机名称",
                              align: "center",
                              prop: "printerName",
                            },
                            scopedSlots: _vm._u([
                              {
                                key: "default",
                                fn: function (scope) {
                                  return [
                                    _c(
                                      "el-form-item",
                                      {
                                        attrs: {
                                          prop:
                                            "tableDataAvailable." +
                                            scope.$index +
                                            ".printerName",
                                          rules:
                                            _vm.rules[
                                              "tableDataAvailable.printerName"
                                            ],
                                        },
                                      },
                                      [
                                        _c(
                                          "el-select",
                                          {
                                            attrs: {
                                              size: "mini",
                                              placeholder: "请选择",
                                              clearable: "",
                                              filterable: "",
                                            },
                                            model: {
                                              value: scope.row.printerName,
                                              callback: function ($$v) {
                                                _vm.$set(
                                                  scope.row,
                                                  "printerName",
                                                  $$v
                                                )
                                              },
                                              expression:
                                                "scope.row.printerName",
                                            },
                                          },
                                          _vm._l(
                                            _vm.printerList,
                                            function (item) {
                                              return _c("el-option", {
                                                key: item.value,
                                                attrs: {
                                                  label: item.name,
                                                  value: item.name,
                                                },
                                              })
                                            }
                                          ),
                                          1
                                        ),
                                      ],
                                      1
                                    ),
                                  ]
                                },
                              },
                            ]),
                          }),
                          _c("el-table-column", {
                            attrs: {
                              "show-overflow-tooltip": "",
                              label: "打印机份数",
                              align: "center",
                              prop: "printCount",
                            },
                            scopedSlots: _vm._u([
                              {
                                key: "default",
                                fn: function (scope) {
                                  return [
                                    _c(
                                      "el-form-item",
                                      {
                                        attrs: {
                                          prop:
                                            "tableDataAvailable." +
                                            scope.$index +
                                            ".printCount",
                                          rules:
                                            _vm.rules[
                                              "tableDataAvailable.printCount"
                                            ],
                                        },
                                      },
                                      [
                                        _c("el-input", {
                                          attrs: { type: "number" },
                                          model: {
                                            value: scope.row.printCount,
                                            callback: function ($$v) {
                                              _vm.$set(
                                                scope.row,
                                                "printCount",
                                                $$v
                                              )
                                            },
                                            expression: "scope.row.printCount",
                                          },
                                        }),
                                      ],
                                      1
                                    ),
                                  ]
                                },
                              },
                            ]),
                          }),
                          _c("el-table-column", {
                            attrs: {
                              "show-overflow-tooltip": "",
                              label: "适用打印方案",
                              align: "center",
                              prop: "printCaseId",
                            },
                            scopedSlots: _vm._u([
                              {
                                key: "default",
                                fn: function (scope) {
                                  return [
                                    _c(
                                      "el-form-item",
                                      {
                                        attrs: {
                                          prop:
                                            "tableDataAvailable." +
                                            scope.$index +
                                            ".printCaseId",
                                          rules:
                                            _vm.rules[
                                              "tableDataAvailable.printCaseId"
                                            ],
                                        },
                                      },
                                      [
                                        _c(
                                          "el-select",
                                          {
                                            attrs: {
                                              placeholder: "请选择",
                                              filterable: "",
                                              clearable: "",
                                            },
                                            model: {
                                              value: scope.row.printCaseId,
                                              callback: function ($$v) {
                                                _vm.$set(
                                                  scope.row,
                                                  "printCaseId",
                                                  $$v
                                                )
                                              },
                                              expression:
                                                "scope.row.printCaseId",
                                            },
                                          },
                                          _vm._l(
                                            _vm.listCookPrint,
                                            function (item) {
                                              return _c("el-option", {
                                                key: item.printCaseId,
                                                attrs: {
                                                  label: item.printCaseName,
                                                  value: item.printCaseId,
                                                },
                                              })
                                            }
                                          ),
                                          1
                                        ),
                                      ],
                                      1
                                    ),
                                  ]
                                },
                              },
                            ]),
                          }),
                        ],
                        1
                      ),
                      _c("pagination", {
                        directives: [
                          {
                            name: "show",
                            rawName: "v-show",
                            value: _vm.total > 0,
                            expression: "total > 0",
                          },
                        ],
                        attrs: {
                          total: _vm.total,
                          page: _vm.queryParams.pageNum,
                          limit: _vm.queryParams.pageSize,
                        },
                        on: {
                          "update:page": function ($event) {
                            return _vm.$set(_vm.queryParams, "pageNum", $event)
                          },
                          "update:limit": function ($event) {
                            return _vm.$set(_vm.queryParams, "pageSize", $event)
                          },
                          pagination: _vm.getList,
                        },
                      }),
                    ],
                    1
                  ),
                ]),
              ]),
            ],
            2
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }